import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AppUrls } from 'src/environments/app-urls';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
declare var $: any;
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pregnancy-treatment-list',
  templateUrl: './pregnancy-treatment-list.component.html',
  styleUrls: ['./pregnancy-treatment-list.component.css']
})
export class PregnancyTreatmentListComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;
  dateFilter: any;
  treatMentsList: any = [];
  table: any;

  constructor(private _httpServ: HttpService, private datePipe: DatePipe
  ) {
    this.dateFilter = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    console.log(this.dateFilter);
    
  }

  ngOnInit() {
    this.treatMentsList = [];
    $("#pregTreatmentsListID").dataTable().fnDestroy();
    this.getTreatMentsList(this.dateFilter);
  }

  getTreatMentsList(dt) {
    let params = {
      action: 'getDailyPregTreatList',
      date_input: dt
    }
    this._httpServ.post(params, this.subUrl).subscribe(resp => {
      console.log(resp)
      if (resp) {
        if(resp.length > 0)
          this.treatMentsList = resp;
          
        setTimeout(() => {
          /* $("#testTB").delegate("#pregTreatmentsListID", function () {
            $(this).DataTable();
          }); */
          // $("#pregTreatmentsListID").dataTable().fnDestroy();
          $('#pregTreatmentsListID').DataTable(
            {
              orderCellsTop: true,
              fixedHeader: true,
              dom: 'Bfrtip',
              buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5',
                'print'
              ],
              // "bDestroy": true
            }
          );
        }, 1000);

      }
    });
  }

  orgValueChange(val){
    let val1 = val.split('/');
    let final = val1[2] + '-' + val1[0] + '-' +  val1[1]
    // console.log(val2)
    this.treatMentsList = [];
    $("#pregTreatmentsListID").dataTable().fnDestroy();
    this.getTreatMentsList(final);
  }

}
