import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AppUrls } from 'src/environments/app-urls';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material';
import * as $ from 'jquery';
declare var $: any;
import { GoogleChartInterface } from 'ng2-google-charts/google-charts-interfaces';
import { ChartSelectEvent, GoogleChartComponent } from 'ng2-google-charts';

@Component({
  selector: 'app-admin-selected-animal',
  templateUrl: './admin-selected-animal.component.html',
  styleUrls: ['./admin-selected-animal.component.css']
})
export class AdminSelectedAnimalComponent implements OnInit {

  public orgChart: GoogleChartInterface = {
    chartType: 'OrgChart',
    dataTable: [
      ['Name', 'Manager', 'Tooltip']
    ],
    options: {
      allowHtml: true,
      allowCollapse: false
    }
  };

  selectedAnimalId: String;
  pendingTasksByAnimal: any;
  subscription: Subscription;
  userData: any;

  data = [
    'Category',
    'ClassType',
    'SpeciesType',
    'BreedType',
    'Gender',
    'BehaviourType',
    'ColorType',
    'FurType',
    'DefectType',
    'ExitReasons',
    'Destination',
    'Owner',
    'TreatMent',
    'WorkArea'
  ];

  subUrl: string = AppUrls._animalInfoModule;

  // data holders
  Category: any;
  ClassType: any;
  SpeciesType: any;
  BreedType: any;
  Gender: any;
  BehaviourType: any;
  ColorType: any;
  FurType: any;
  DefectType: any;
  ExitReasons: any;
  Destination: any;
  Owner: any;
  Location: any;
  locationsTable: any;
  weightsTable: any;
  assignedToList: any;
  puppyPatterns: any;
  puppyPreventativePatterns: any;
  adultPatterns: any;
  adultPreventativePatterns: any;
  navigationData: any;
  initNavigationData: any;
  selectedAnimalDetails: any;
  preventativeHistory: any;
  TreatMent: any
  WorkArea: any;
  reprodPuppyList: any;
  reprodMatingList: any;
  documentData: any;
  geneoData: any;

  // Select Option Properties
  categoryOption1: any = 'Select Category';
  @ViewChild('categoryOption') categoryOption: ElementRef;
  @ViewChild('locationOption') locationOption: ElementRef;
  @ViewChild('classOption') classOption: ElementRef;
  @ViewChild('speciesOption') speciesOption: ElementRef;
  @ViewChild('breedOption') breedOption: ElementRef;
  @ViewChild('genderOption') genderOption: ElementRef;
  @ViewChild('behaveOption') behaveOption: ElementRef;
  @ViewChild('colorOption') colorOption: ElementRef;
  @ViewChild('furOption') furOption: ElementRef;
  @ViewChild('defectOption') defectOption: ElementRef;
  @ViewChild('exitOption') exitOption: ElementRef;
  @ViewChild('destinationOption') destinationOption: ElementRef;
  @ViewChild('ownerOption') ownerOption: ElementRef;
  @ViewChild('assignToUser') assignToUser: ElementRef;
  @ViewChild('puppyOption') puppyOption: ElementRef;
  @ViewChild('adultOption') adultOption: ElementRef;
  @ViewChildren('treatOption') treatOption: QueryList<ElementRef>
  @ViewChildren('WAOption') WAOption: QueryList<ElementRef>
  @ViewChild('treatOptionAdd') treatOptionAdd: ElementRef;
  @ViewChild('WAOptionAdd') WAOptionAdd: ElementRef;
  // @ViewChild('isAlive') isAlive: ElementRef;
  isAlive: any;
  // Input Properties
  tattoo: any;
  chip: any;
  label: any;
  offered: any;
  reserved: any;
  others: any;
  @ViewChild('litter') litter: ElementRef;
  animalNewWeight: any;
  @ViewChildren('histWeight') histWeight: QueryList<ElementRef>;
  @ViewChildren('histCreated') histCreated: QueryList<ElementRef>;
  @ViewChildren('histDays') histDays: QueryList<ElementRef>;
  @ViewChild('histWeightAdd') histWeightAdd: ElementRef;
  @ViewChild('histCreatedAdd') histCreatedAdd: ElementRef;
  @ViewChild('histDaysAdd') histDaysAdd: ElementRef;

  // text Area options
  notes: any
  assignToTask: string;

  // Check Box properties
  curve: any;
  nuetered: any;
  procedure: any;
  active: any;

  // Radio Buttons
  @ViewChild('wean1') wean1: ElementRef;
  @ViewChild('wean2') wean2: ElementRef;

  // dates options 
  @ViewChild('addLocationDate') addLocationDate: ElementRef;
  @ViewChild('birthDate') birthDate: ElementRef;
  @ViewChild('plannedDate') plannedDate: ElementRef;
  @ViewChild('realDate') realDate: ElementRef;
  @ViewChild('assignToDate') assignToDate: ElementRef;
  @ViewChild('addWeightDate') addWeightDate: ElementRef;
  @ViewChildren('histStartDate') histStartDate: QueryList<ElementRef>
  @ViewChildren('histEndDate') histEndDate: QueryList<ElementRef>
  @ViewChild('histStartDateAdd') histStartDateAdd: ElementRef;
  @ViewChild('histEndDateAdd') histEndDateAdd: ElementRef;

  //  booleans 
  disableFirstLast: boolean = true;

  // Close Modals
  @ViewChild('closeAddTaskModal') closeAddTaskModal: ElementRef;
  @ViewChild('closeaddLocModal') closeaddLocModal: ElementRef;
  @ViewChild('closeaddweightModal') closeaddweightModal: ElementRef;
  @ViewChild('closeDocumentModal') closeDocumentModal: ElementRef;


  // file properties
  @ViewChild('fileName') fileName: ElementRef;
  fileWidth: any;
  fileHeight: any;
  fileResolution: any;
  @ViewChild('fileSize') fileSize: ElementRef;
  fileDescription: any;

  // Tabs properties
  @ViewChild('dataTab') dataTab: ElementRef;
  @ViewChild('globalHist') globalHist: ElementRef;
  @ViewChild('preventHist') preventHist: ElementRef;
  @ViewChild('services') services: ElementRef;
  @ViewChild('documents') documents: ElementRef;
  @ViewChild('reprodHist') reprodHist: ElementRef;
  @ViewChild('geneology') geneology: ElementRef;

  // others
  endodedFile: any;
  addedFile: any;
  fileUrl;
  selectedTab: any;
  
  constructor(private _http: HttpService, private _activeRoute: ActivatedRoute,
    private _userService: UserService, public snackBar: MatSnackBar, private _router: Router
  ) { }

  ngOnInit() {
    this.getSelectedTab();
    // gets User Details
    this.subscription = this._userService.message.subscribe(userStatus => {
      if (userStatus["avilableUserData"]) {
        this.userData = this._userService.getUserData();
      }
    });
    this.userData = this._userService.getUserData();
    // gets Activated route details
    this._activeRoute.params.subscribe(resp => {
      this.selectedAnimalId = resp['id'];
    });
    this.getAllInitialData();
    this.dataTab.nativeElement.click();
  }

  getSelectedTab() {
    if (localStorage.getItem('selectedTab') && localStorage.getItem('selectedTab') != null) {
      this.selectedTab = localStorage.getItem('selectedTab');
      if (this.selectedTab == 'globalHist') {
        this.globalHist.nativeElement.click();
      }
      else if (this.selectedTab == 'preventHist') {
        this.preventHist.nativeElement.click();
      }
      else if (this.selectedTab == 'services') {
        this.services.nativeElement.click();
      }
      else if (this.selectedTab == 'documents') {
        this.documents.nativeElement.click();
      }
      else if (this.selectedTab == 'reprodHist') {
        this.reprodHist.nativeElement.click();
      }
      else if (this.selectedTab == 'geneology') {
        this.geneology.nativeElement.click();
      }
    }
    else {
      this.dataTab.nativeElement.click();
    }
  }

  storeSelectedTab(name) {
    localStorage.setItem('selectedTab', name);
  }

  getAllInitialData() {
    //  prevatative hist tab
    this.getPreventativeHistory();
    // general
    this.isMaxID(this.selectedAnimalId);
    // data tab
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
    this.getSelectedAnimalDetails();
    this.getLocations();
    this.getPendingTasksByAnimal();
    this.getLocationsTable();
    this.getWeightsTable();
    this.getPuppyPatterns();
    this.getAdultPatterns();
    this.getPuppyPreventativePatterns();
    this.getAdultPreventativePatterns();
    // geneology tab
    this.getGeneology();
    //  reprod tab
    this.getReprodMatingList();
    this.getReprodPuppyList();
    // downloada tab
    this.getDownloadsData();
    setTimeout(() => {
      this.datePickers();
    }, 3000);
  }

  // navigation methods

  isMaxID(id) {
    let params = {
      action: 'isMaxAnimalId',
      animalId: id
    }
    this._http.post(params, this.subUrl).subscribe(resp => {      
      if (resp) {
        this.initNavigationData = resp['nav'];
      }
    });
  }

  getNavigationDetails(position) {
    let params = {
      action: 'getAnimalNavigations',
      animalId: this.selectedAnimalId,
      navPos: position
    }
    this._http.post(params, this.subUrl).subscribe(resp => {          
      if (resp) {
        this.navigationData = resp[0];
        this._router.navigate([`/selected-animal/${this.navigationData.id}`]);
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  // Initially Loaded Methods
  // data tab

  getCategories(type) {
    let params = {
      action: 'getCategories',
      categoryType: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        if (type == 'Category') {
          this.Category = resp;
        }
        else if (type == 'ClassType') {
          this.ClassType = resp;
        }
        else if (type == 'SpeciesType') {
          this.SpeciesType = resp;

        }
        else if (type == 'BreedType') {
          this.BreedType = resp;

        }
        else if (type == 'Gender') {
          this.Gender = resp;

        }
        else if (type == 'BehaviourType') {
          this.BehaviourType = resp;
        }
        else if (type == 'ColorType') {
          this.ColorType = resp;
        }
        else if (type == 'FurType') {
          this.FurType = resp;

        }
        else if (type == 'DefectType') {
          this.DefectType = resp;
        }
        else if (type == 'ExitReasons') {
          this.ExitReasons = resp;
        }
        else if (type == 'Destination') {
          this.Destination = resp;
        }
        else if (type == 'Owner') {
          this.Owner = resp;
        }
        else if (type == 'TreatMent') {
          this.TreatMent = resp;
        }
        else if (type == 'WorkArea') {
          this.WorkArea = resp;
        }
      }
    });
  }

  getSelectedAnimalDetails() {
    let params = {
      action: 'viewAnimalById',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {

      if (resp) {
        this.selectedAnimalDetails = resp[0];
        console.log(this.selectedAnimalDetails);
        
        this.tattoo = this.selectedAnimalDetails.tattoo;
        this.chip = this.selectedAnimalDetails.chipID;
        this.label = this.selectedAnimalDetails.label;
        this.offered = this.selectedAnimalDetails.offeredTo;
        this.reserved = this.selectedAnimalDetails.reservedFor;
        this.others = this.selectedAnimalDetails.others;
        this.notes = this.selectedAnimalDetails.notes;
        this.isAlive = this.selectedAnimalDetails.isAlive;
        if (this.selectedAnimalDetails.newWeightDate == null) {
          this.datePickers2();
        }
      }
    });
  }

  getLocations() {
    let params = {
      action: 'getLocations'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.Location = resp;
      }
    });
  }

  getPendingTasksByAnimal() {
    let params = {
      action: 'viewAnimalPendingTaskById',
      animalId: this.selectedAnimalId,
      assignedUser: this.userData['Email']
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.pendingTasksByAnimal = resp;
      }
    });
  }

  getLocationsTable() {
    let params = {
      action: 'getAnimalLocations',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.locationsTable = resp;
      }
    });
  }

  getWeightsTable() {
    let params = {
      action: 'getAnimalWeights',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.weightsTable = resp;
      }
    });
  }

  getPuppyPatterns() {
    let params = {
      action: 'getPuppyPreventativeGuidelines'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.puppyPatterns = resp;
      }
    });
  }

  getPuppyPreventativePatterns() {
    let params = {
      action: 'getPuppyTreatmentGuidelines',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.puppyPreventativePatterns = resp;
      }
    });
  }

  getAdultPatterns() {
    let params = {
      action: 'getAdultPreventativeGuidelines'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.adultPatterns = resp;
      }
    });
  }

  getAdultPreventativePatterns() {
    let params = {
      action: 'getAdultTreatmentGuidelines',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.adultPreventativePatterns = resp;
      }
    });
  }

  datePickers() {
    for (let i = 0; i < this.preventativeHistory.length; i++) {
      $("#picker3").delegate("#histStartDate" + i, "focusin", function () {
        $(this).datepicker({
          dateFormat: 'yy-mm-dd',
          onSelect: function (datetext) {
            var d = new Date();
            datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
            $(this).val(datetext);
          }
        });
      });
    }
    for (let i = 0; i < this.preventativeHistory.length; i++) {
      $("#picker3").delegate("#histEndDate" + i, "focusin", function () {
        $(this).datepicker({
          dateFormat: 'yy-mm-dd',
          onSelect: function (datetext) {
            var d = new Date();
            datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
            $(this).val(datetext);
          }
        });
      });
    }
    $('#addLocationDate,#addWeightDate,#birthDate,#plannedDate,#realDate,#assignToDate,#histStartDateAdd,#histEndDateAdd').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        $(this).val(datetext);
      }
    });
  }

  datePickers2() {
    $('#addWeightDate').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        $(this).val(datetext);
      }
    });
  }

  addAnimalLocation() {
    // Preventing api call if user does not select an optoin
    if (this.locationOption.nativeElement.value != 'Select Location') {
      if(this.addLocationDate.nativeElement.value == ''){
        this.openSnackBar('Please select Date', '5000', 'close');
      } else {
        let params = {
          action: 'addAnimalLocation',
          animalID: this.selectedAnimalId,
          locDate: this.addLocationDate.nativeElement.value,
          locationId: this.locationOption.nativeElement.value
        }
        // console.log(params);
        
        this._http.post(params, this.subUrl).subscribe(resp => {
          if (resp['status'] == "success") {
            this.closeaddLocModal.nativeElement.click();
            this.openSnackBar(resp['msg'], '5000', 'close');
            this.getSelectedAnimalDetails();
            this.getLocationsTable();
            this.locationOption.nativeElement.value = 'Select Location';
            this.addLocationDate.nativeElement.value = null;
          }
          else {
            this.openSnackBar(resp['msg'], '5000', 'close');
          }
        });
      }
    } else {
      this.openSnackBar('Please select Location', '5000', 'close');
    }
  }

  addAnimalWeight() {
    console.log(this.animalNewWeight)
    // Preventing api call if user does not select an optoin      
    if (this.animalNewWeight == '' || this.animalNewWeight == undefined) {
      this.openSnackBar('Please add Weight', '5000', 'close');
    } else {      
      if(this.addWeightDate.nativeElement.value == ''){
        this.openSnackBar('Please select Date', '5000', 'close');
      } else {
        let params = {
          action: 'addAnimalWeights',
          animalID: this.selectedAnimalId,
          weight: this.animalNewWeight,
          weightDate: this.addWeightDate.nativeElement.value
        }
        this._http.post(params, this.subUrl).subscribe(resp => {
          if (resp['status'] == "success") {
            this.closeaddweightModal.nativeElement.click();
            this.openSnackBar(resp['msg'], '5000', 'close');
            this.getSelectedAnimalDetails();
            this.getWeightsTable();
            this.animalNewWeight = null;
            this.addWeightDate.nativeElement.value = null;
            

          }
          else {
            this.openSnackBar(resp['msg'], '5000', 'close');
          }
        });
      }
    }
  }

  addPendingTask() {
    // Preventing api call if user does not select an optoin
    if (this.assignToUser.nativeElement.value != 'Select Assign To') {
      let params = {
        action: 'addAnimalPendingTasks',
        animalId: this.selectedAnimalId,
        animalPendingTask: this.assignToTask,
        animalPendingTaskDate: this.assignToDate,
        owner: this.userData['Email'],
        assignedUser: this.assignToUser
      }
      this._http.post(params, this.subUrl).subscribe(resp => {
        if (resp['status'] == 'success') {
          this.closeAddTaskModal.nativeElement.click();
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
        else {
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
      });
    }
  }

  getAssignedToList() {
    let params = {
      action: 'getAssignedUsersTo',
      userEmail: this.userData['Email']
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.assignedToList = resp;
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  updatePuppyPattern() {
    // Preventing api call if user does not select an optoin
    if (this.puppyOption.nativeElement.value != 'Select Pattern') {
      let params = {
        action: 'updatePuppyPattern',
        id: this.puppyOption.nativeElement.value,
        animalId: this.selectedAnimalId
      }
      this._http.post(params, this.subUrl).subscribe(resp => {
        if (resp['status'] == 'success') {
          this.openSnackBar(resp['msg'], '5000', 'close');
          this.getPuppyPreventativePatterns();
        }
        else {
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
      });
    }
  }

  updateAdultPattern() {
    // Preventing api call if user does not select an optoin
    if (this.adultOption.nativeElement.value != 'Select Pattern') {
      let params = {
        action: 'updateAdultPattern',
        id: this.adultOption.nativeElement.value,
        animalId: this.selectedAnimalId
      }
      this._http.post(params, this.subUrl).subscribe(resp => {
        if (resp['status'] == 'success') {
          this.openSnackBar(resp['msg'], '5000', 'close');
          this.getAdultPreventativePatterns();
        }
        else {
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
      });
    }
  }


  downloadFile(doc) {
    let params = {
      action: 'downloadFile',
      file: doc.fileName
    }
    this._http.downloadFile(params, this.subUrl).subscribe(resp => {
      var b64toBlob = require('b64-to-blob');
      var contentType = resp.mime_type;
      var b64Data = resp.file_data;
      var blob = b64toBlob(b64Data, contentType);
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", doc.name);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }), error => console.log('Error downloading the file'),
      () => console.info('File downloaded successfully');
  }

  getDownloadsData() {
    let params = {
      action: 'getDocuments',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.documentData = resp;
      }
    });
  }

  fileUpload(evt) {
    var files = evt.target.files;
    this.addedFile = files[0];
    if (files && this.addedFile) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.addedFile);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    let encodedImage = btoa(binaryString);
    // this.endodedFile = encodedImage;
    this.endodedFile = encodeURIComponent(encodedImage);
  }

  sendFileToServer() {
    let params = {
      action: 'addDocumentUpload',
      animalID: this.selectedAnimalId,
      name: this.fileName.nativeElement.value == undefined ? '' : this.fileName.nativeElement.value,
      description: this.fileDescription == undefined ? '' : this.fileDescription,
      width: this.fileWidth == undefined ? 0 : this.fileWidth,
      height: this.fileHeight == undefined ? 0 : this.fileHeight,
      resolution: this.fileResolution == undefined ? 0 : this.fileResolution,
      size: this.fileSize.nativeElement.value == undefined ? '' : this.fileSize.nativeElement.value,
      encoded_string: this.endodedFile,
      mimeType: this.addedFile.type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] == 'success') {
        this.getDownloadsData();
        this.closeDocumentModal.nativeElement.click();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  viewSelectedMating() {
    this._router.navigate([`/selected-mating/${this.selectedAnimalDetails.matingID}`]);
  }

  // Re productive History tab

  getReprodPuppyList() {
    let params = {
      action: 'getReprodHistPuppies',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.reprodPuppyList = resp;
      }
    });
  }

  getReprodMatingList() {
    let params = {
      action: 'getReprodHistMating',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.reprodMatingList = resp;
      }
    });
  }

  gotoSelectPuppy(id) {
    this._router.navigate([`/selected-animal/${id}`]);
  }

  gotoSelectMating(id) {
    this._router.navigate([`/selected-mating/${id}`]);
  }

  // Preventative History Tab
  getPreventativeHistory() {
    let params = {
      action: 'viewAnimalPrevHist',
      animalId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.preventativeHistory = resp;
      }
    });
  }

  addPreventativeHistory() {
    let params = {
      action: 'addAnimalPreventiveHist',
      animalId: this.selectedAnimalId,
      created: this.userData['UserName'],
      date: this.histStartDateAdd.nativeElement.value == undefined ? '' : this.histStartDateAdd.nativeElement.value,
      days: this.histDaysAdd.nativeElement.value == undefined ? '' : this.histDaysAdd.nativeElement.value,
      endDate: this.histEndDateAdd.nativeElement.value == undefined ? '' : this.histEndDateAdd.nativeElement.value,
      treatmentID: this.treatOptionAdd.nativeElement.value == 'Select TreatMent' ? '' : this.treatOptionAdd.nativeElement.value,
      weight: this.histWeightAdd.nativeElement.value == undefined ? '' : this.histWeightAdd.nativeElement.value,
      workAreaID: this.WAOptionAdd.nativeElement.value == 'Select WorkArea' ? '' : this.WAOptionAdd.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] == 'success') {
        this.getPreventativeHistory();
        this.histStartDateAdd.nativeElement.value = null;
        this.histDaysAdd.nativeElement.value = null;
        this.histEndDateAdd.nativeElement.value = null;
        this.treatOptionAdd.nativeElement.value = 'Select TreatMent';
        this.histWeightAdd.nativeElement.value = null;
        this.WAOptionAdd.nativeElement.value = 'Select WorkArea';
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  updatePreventativeHistory(id, i) {
    let params = {
      action: 'updateAnimalPreventiveHist',
      animalPrevHistID: id,
      animalId: this.selectedAnimalId,
      date: this.histStartDate.toArray()[i].nativeElement.value == undefined ? '' : this.histStartDate.toArray()[i].nativeElement.value,
      days: this.histDays.toArray()[i].nativeElement.value == undefined ? '' : this.histDays.toArray()[i].nativeElement.value,
      endDate: this.histEndDate.toArray()[i].nativeElement.value == undefined ? '' : this.histEndDate.toArray()[i].nativeElement.value,
      treatmentID: this.treatOption.toArray()[i].nativeElement.value == 'Select TreatMent' ? '' : this.treatOption.toArray()[i].nativeElement.value,
      weight: this.histWeight.toArray()[i].nativeElement.value == undefined ? '' : this.histWeight.toArray()[i].nativeElement.value,
      workAreaID: this.WAOption.toArray()[i].nativeElement.value == 'Select WorkArea' ? '' : this.WAOption.toArray()[i].nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] == 'success') {
        this.getPreventativeHistory();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  deletePreventativeHistory(id) {
    let params = {
      action: 'deleteAnimalPrevHist',
      animalPrevHistID: id
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] == 'success') {
        this.getPreventativeHistory();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  // Geneology tab

  getGeneology() {
    let params = {
      action: 'genealogy',
      childId: this.selectedAnimalId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {     
        this.geneoData = resp;
        this.orgChart.dataTable.push([{ v: this.selectedAnimalId, f: `<div class="${this.geneoData.length == 0 ? 'selectedAnimal' : ''}">${this.selectedAnimalId}</div>` }, '', this.selectedAnimalId]);
        if (this.geneoData.length == 0) {
          this.orgChart.component.draw();
        }
        for (let i = 0; i < this.geneoData.length; i++) {
          if (this.geneoData[i].parent_id == "XXXXXX") {
            this.geneoData[i].parent_id = "XXXXXX" + `<span style="display:none">${i}</span>`;
          }
          else if (this.geneoData[i].parent_id == "YYYYYY") {
            this.geneoData[i].parent_id = "YYYYYY" + `<span style="display:none>${i}</span>`;
          }
          if (this.geneoData[i].gender == 'M') {
            this.orgChart.dataTable.push([{ v: this.geneoData[i].parent_id, f: `<a><div class="maleCSS">${this.geneoData[i].parent_id}</div></a>` }, this.geneoData[i].AnimalID, this.geneoData[i].parent_id]);
          }
          else {
            this.orgChart.dataTable.push([{ v: this.geneoData[i].parent_id, f: `<a><div class="femaleCSS">${this.geneoData[i].parent_id}</div></a>` }, this.geneoData[i].AnimalID, this.geneoData[i].parent_id]);
          }
          if (i == this.geneoData.length - 1) {
            // this.googleChart.draw();
            this.orgChart.component.draw();
          }
        }
      }
    });
  }

  public select(event: ChartSelectEvent) {
    if(event.message == "select"){
      if (!event.selectedRowValues[0].includes('XXXXXX') && !event.selectedRowValues[0].includes('YYYYYY')) {        
        this._router.navigate(['/selected-animal/' + event.selectedRowValues[0]]);
      }  
    }  
  }

  // update entire data 
  updateAnimal() {
    let params = {
      action: 'updateAnimalList',
      categoryId: this.categoryOption.nativeElement.value == 'Select Category' ? '' : this.categoryOption.nativeElement.value,
      currentLocation: this.selectedAnimalDetails.locationName,
      locationID: this.selectedAnimalDetails.locId,
      weight: this.selectedAnimalDetails.weight == null ? '' : this.selectedAnimalDetails.weight,
      classTypeId: this.classOption.nativeElement.value == 'Select Class' ? '' : this.classOption.nativeElement.value,
      speciesTypeId: this.speciesOption.nativeElement.value == 'Select Species' ? '' : this.speciesOption.nativeElement.value,
      breedTypeId: this.breedOption.nativeElement.value == 'Select Breed' ? '' : this.breedOption.nativeElement.value,
      sex: this.genderOption.nativeElement.value == 'Select Gender' ? '' : this.genderOption.nativeElement.value,
      tattoo: this.tattoo == undefined ? '' : this.tattoo,
      chipId: this.chip == undefined ? '' : this.chip,
      origin: this.selectedAnimalDetails.origin,
      behaviourTypeId: this.behaveOption.nativeElement.value == 'Select Behaviour' ? '' : this.behaveOption.nativeElement.value,
      colorTypeId: this.colorOption.nativeElement.value == 'Select Color' ? '' : this.colorOption.nativeElement.value,
      furTypeId: this.furOption.nativeElement.value == 'Select Fur' ? '' : this.furOption.nativeElement.value,
      defectTypeId: this.defectOption.nativeElement.value == 'Select Defect' ? '' : this.defectOption.nativeElement.value,
      label: this.label == undefined ? '' : this.label,
      offeredTo: this.offered == undefined ? '' : this.offered,
      reservedFor: this.reserved == undefined ? '' : this.reserved,
      others: this.others == undefined ? '' : this.others,
      dateOfBirth: this.birthDate.nativeElement.value,
      litterOfOrigin: this.litter.nativeElement.value == undefined ? '' : this.litter.nativeElement.value,
      resultOfWeaning: this.wean2.nativeElement.checked == true ? 'w' : this.wean1.nativeElement.checked == true ? 'LD' : '',
      plannedDate: this.plannedDate.nativeElement.value,
      realDate: this.realDate.nativeElement.value,
      exitReasonId: this.exitOption.nativeElement.value == 'Select Reason' ? '' : this.exitOption.nativeElement.value,
      destination: this.destinationOption.nativeElement.value == 'Select Destination' ? '' : this.destinationOption.nativeElement.value,
      owner: this.ownerOption.nativeElement.value == 'Select Owner' ? '' : this.ownerOption.nativeElement.value,
      notes: this.notes == undefined ? '' : this.notes,
      usedForTheGrowthCurve: this.curve == true ? 1 : 0,
      neuteredAbunak: this.nuetered == true ? 1 : 0,
      inUseOrToBeUsedInFuture: this.procedure == true ? 1 : 0,
      updatedBy: this.userData['Email'],
      animalId: this.selectedAnimalId
    }
    console.log(params);
    
    // return;
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getSelectedAnimalDetails();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}
