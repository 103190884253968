import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppUrls } from 'src/environments/app-urls';
import { HttpService } from 'src/app/services/http.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { MatSnackBar } from '@angular/material';

declare var $: any;

@Component({
  selector: 'app-matings-list',
  templateUrl: './matings-list.component.html',
  styleUrls: ['./matings-list.component.css']
})
export class MatingsListComponent implements OnInit {
  subUrl: string = AppUrls._animalInfoModule;

  matingListData:any;
  dataTable: any;
  animalListData: any;

  constructor(private _http: HttpService, private chRef: ChangeDetectorRef,public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getMatingList();
  }
  getMatingList() {
    let params = {
      action: 'viewMatingDetails'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {  
      if (resp) {
        this.matingListData = resp;
        setTimeout(() => {
          $('#matingListTable').DataTable({
            dom: 'Bfrtip',
            buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5'        
            ]  
        });
        }, 500);       
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}
