import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material';
import { AppUrls } from 'src/environments/app-urls';
import * as $ from 'jquery';
import { Subscription } from 'rxjs';
declare var $: any;


@Component({
  selector: 'app-add-mating',
  templateUrl: './add-mating.component.html',
  styleUrls: ['./add-mating.component.css']
})
export class AddMatingComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;
  subscription: Subscription;
  userData: object;

  data = [
    'SpeciesType',
    'PregnancyMethod'
  ];

  // data holders
  SpeciesType: any;
  PregnancyMethod: any
  maleCategory: any;
  femaleCategory: any;
  maleTattoo: any;
  femaleTattoo: any;
  maleChip: any;
  femaleChip: any;

  // select option properties 
  @ViewChild('speciesOption') speciesOption: ElementRef;
  @ViewChild('categoryOptionMale') categoryOptionMale: ElementRef;
  @ViewChild('categoryOptionFemale') categoryOptionFemale: ElementRef;
  @ViewChild('tattooOptionMale') tattooOptionMale: ElementRef;
  @ViewChild('tattooOptionFemale') tattooOptionFemale: ElementRef;
  @ViewChild('methodOption') methodOption: ElementRef;
  @ViewChild('genderOption') genderOption: ElementRef;

  // input options
  @ViewChild('chipIDMale') chipIDMale: ElementRef;
  @ViewChild('animalIdMale') animalIdMale: ElementRef;
  @ViewChild('chidIDFeMale') chidIDFeMale: ElementRef;
  @ViewChild('animalIdFeMale') animalIdFeMale: ElementRef;
  foreseen: any;

  // date options
  @ViewChild('pregnancyDate') pregnancyDate: ElementRef;
  @ViewChild('txtDateEntry') txtDateEntry: ElementRef;
  @ViewChild('txtDateExit') txtDateExit: ElementRef;
  @ViewChild('txtDateMatingConf') txtDateMatingConf: ElementRef;
  @ViewChild('txtEstdDate') txtEstdDate: ElementRef;
  @ViewChild('txtRealBirthDate') txtRealBirthDate: ElementRef;
  @ViewChild('txtPuppyBirthDate') txtPuppyBirthDate: ElementRef;
  matingExitDateValue: any;
  estimatedDateValue: any;

  // check boxes
  @ViewChild('isPregnancyConfirm') isPregnancyConfirm: ElementRef;
  @ViewChild('notMated') notMated: ElementRef;
  // @ViewChild('isMatingConfirm') isMatingConfirm: ElementRef;
  @ViewChild('chkProblem') chkProblem: ElementRef;
  @ViewChild('chkNotAlive') chkNotAlive: ElementRef;
  @ViewChild('chkFixedPattern') chkFixedPattern: ElementRef;

  // radio Button options
  @ViewChild('LD') LD: ElementRef;
  @ViewChild('BD') BD: ElementRef;
  @ViewChild('W') W: ElementRef;

  // Text area
  problemsInfo: string;

  constructor(private _http: HttpService,
    private _userService: UserService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.subscription = this._userService.message.subscribe(userStatus => {
      if (userStatus["avilableUserData"]) {
        this.userData = this._userService.getUserData();
      }
    });
    this.userData = this._userService.getUserData();
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
    setTimeout(() => {
      this.datePickers();
    }, 1000);
    
  }

  getExitDate(entryDate) {
    this.txtDateEntry.nativeElement.value = entryDate;
    let params = {
      action: 'getExitDate',
      entryDt: this.txtDateEntry.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        // console.log(resp);        
        this.matingExitDateValue = resp[0]['exitDate'];
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  getEstimatedDate(estdDate) {
    this.txtDateMatingConf.nativeElement.value = estdDate;
    let params = {
      action: 'getEstimated',
      selDate: this.txtDateMatingConf.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.estimatedDateValue = resp[0]['estimatdDate'];
        // if (this.estimatedDateValue == null || '') {
        //   this.datePickers2();
        // }
      }
      // else{
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  // Initially Loaded Methods

  getCategories(type) {
    let params = {
      action: 'getCategories',
      categoryType: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        if (type == 'SpeciesType') {
          this.SpeciesType = resp;
        }
        else {
          this.PregnancyMethod = resp;
        }
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  getCategoryBySpecies() {
    for (let i = 0; i < 2; i++) {
      let params = {
        action: 'getCategoryBySpecies',
        'speciesID': this.speciesOption.nativeElement.value,
        sex: i == 0 ? 'M' : 'F'
      }
      this._http.post(params, this.subUrl).subscribe(resp => {
        if (resp) {
          if (i == 0) {
            this.maleCategory = resp;
          }
          else {
            this.femaleCategory = resp;
          }
        }
        // else {
        //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
        // }
      });
    }

  }

  getTattooByCategoryMale() {
    let params = {
      action: 'getTattooByCateg',
      catId: this.categoryOptionMale.nativeElement.value,
      sex: 'M'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.maleTattoo = resp;
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  addMating() {
    let params = {
      action: 'addMatingDetails',
      speciesTypeID: this.speciesOption.nativeElement.value == 'Select Species' ? '' : this.speciesOption.nativeElement.value,
      maleCategoryID: this.categoryOptionMale.nativeElement.value == 'Select Category' ? '' : this.categoryOptionMale.nativeElement.value,
      feMaleCategoryID: this.categoryOptionFemale.nativeElement.value == 'Select Category' ? '' : this.categoryOptionFemale.nativeElement.value,
      maleTattoo: this.tattooOptionMale.nativeElement.value == 'Select Tattoo' ? '' : this.tattooOptionMale.nativeElement.value,
      feMaleTattoo: this.tattooOptionFemale.nativeElement.value == 'Select Tattoo' ? '' : this.tattooOptionFemale.nativeElement.value,
      maleChipID: this.chipIDMale.nativeElement.value == undefined ? '' : this.chipIDMale.nativeElement.value,
      feMaleChipID: this.chidIDFeMale.nativeElement.value == undefined ? '' : this.chidIDFeMale.nativeElement.value,
      maleAnimalID: this.animalIdMale.nativeElement.value == undefined ? '' : this.animalIdMale.nativeElement.value,
      feMaleAnimalID: this.animalIdFeMale.nativeElement.value == undefined ? '' : this.animalIdFeMale.nativeElement.value,
      heatOrMatingEntry: this.txtDateEntry.nativeElement.value == undefined ? '' : this.txtDateEntry.nativeElement.value,
      heatOrMatingExit: this.txtDateExit.nativeElement.value == undefined ? '' : this.txtDateExit.nativeElement.value,
      heatDetectedAndNotMated: this.notMated.nativeElement.checked == true ? 1 : 0,
      isPregnancyConfirmed: this.isPregnancyConfirm.nativeElement.checked == true ? 1 : 0,
      pregnancyConfirmedDate: this.pregnancyDate.nativeElement.value == undefined ? '' : this.pregnancyDate.nativeElement.value,
      pregnancyMethodID: this.methodOption.nativeElement.value == 'Select Method' ? '' : this.methodOption.nativeElement.value,
      foreseenWeanedPuppies: this.foreseen == undefined ? '' : this.foreseen,
      birthForecastDate: this.txtEstdDate.nativeElement.value == undefined ? '' : this.txtEstdDate.nativeElement.value,
      realBirthDate: this.txtRealBirthDate.nativeElement.value == undefined ? '' : this.txtRealBirthDate.nativeElement.value,
      // isProblems: this.chkProblem.nativeElement.checked ? 1 : 0,
      isProblems: 1,
      problemDetails: this.problemsInfo == undefined ? '' : this.problemsInfo,
      isIfNotAliveNewborns: this.chkNotAlive.nativeElement.checked ? 1 : 0,
      createdBy: this.userData['Email'],
      birthDate: this.txtPuppyBirthDate.nativeElement.value == undefined ? '' : this.txtPuppyBirthDate.nativeElement.value,
      sex: this.genderOption.nativeElement.value == 'Select Gender' ? '' : this.genderOption.nativeElement.value,
      puppyStatusMasterID: this.BD.nativeElement.checked == true ? 'BD' : this.LD.nativeElement.checked == true ? 'LD' :
        this.W.nativeElement.checked == true ? 'W' : '',
      // matingConformation: this.isMatingConfirm.nativeElement.checked ? 1 : 0,
      matingConformation: 1,
      matingConfirmedDate: this.txtDateMatingConf.nativeElement.value == undefined ? '' : this.txtDateMatingConf.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.openSnackBar(resp['msg'], '5000', 'close');
        this.resetAll();
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  getTattooByCategoryFeMale() {
    let params = {
      action: 'getTattooByCateg',
      catId: this.categoryOptionFemale.nativeElement.value,
      sex: 'F'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.femaleTattoo = resp;
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  getChipByTattooMale() {
    let params = {
      action: 'getChipByTattoo',
      tattoo: this.tattooOptionMale.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp.length) {
        this.maleChip = resp[0];
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  getChipByTattooFeMale() {
    let params = {
      action: 'getChipByTattoo',
      tattoo: this.tattooOptionFemale.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      console.log(resp);
      if (resp.length) {
        this.femaleChip = resp[0];
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  datePickers() {
    var self = this;
    $('#pregnancyDate,#txtDateExit,#txtEstdDate,#txtRealBirthDate,#txtPuppyBirthDate').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        datetext = datetext;
        // datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        $(this).val(datetext);
      }
    });
    $('#txtDateEntry').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        datetext = datetext;
        // datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        $(this).val(datetext);
        self.getExitDate(datetext);
      }
    });
    $('#txtDateMatingConf').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        datetext = datetext;
        // datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        $(this).val(datetext);
        self.getEstimatedDate(datetext);
      }
    });
  }

  resetAll() {
    this.speciesOption.nativeElement.value = 'Select Species';
    this.categoryOptionMale.nativeElement.value = 'Select Category';
    this.categoryOptionFemale.nativeElement.value = 'Select Category';
    this.tattooOptionMale.nativeElement.value = 'Select Tattoo';
    this.tattooOptionFemale.nativeElement.value = 'Select Tattoo';
    this.chipIDMale.nativeElement.value = null;
    this.chidIDFeMale.nativeElement.value = null;
    this.animalIdMale.nativeElement.value = null;
    this.animalIdFeMale.nativeElement.value = null;
    this.txtDateEntry.nativeElement.value = null;
    this.txtDateExit.nativeElement.value = null;
    this.notMated.nativeElement.checked = false;
    this.isPregnancyConfirm.nativeElement.checked = false;
    this.pregnancyDate.nativeElement.value = null;
    this.methodOption.nativeElement.value = 'Select Method';
    this.foreseen = '';
    this.txtEstdDate.nativeElement.value = null;
    this.txtRealBirthDate.nativeElement.value = null;
    // this.chkProblem.nativeElement.checked = false;
    this.problemsInfo = '';
    this.chkNotAlive.nativeElement.checked = false;

    this.txtPuppyBirthDate.nativeElement.value = null;
    this.genderOption.nativeElement.value = 'Select Gender';
    this.BD.nativeElement.checked = false;
    this.LD.nativeElement.checked = false;
    this.W.nativeElement.checked = false;
    // this.isMatingConfirm.nativeElement.checked = false,
      this.txtDateMatingConf.nativeElement.value = null;
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }
}
