import { Component, OnInit } from '@angular/core';
import { FormValidateService } from 'src/app/services/form-validate.service';
import { Router } from '@angular/router';
import { AppUrls } from 'src/environments/app-urls';
import { HttpService } from 'src/app/services/http.service';
import { MatSnackBar } from '@angular/material';
import { UserService } from 'src/app/services/user.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;
  subscription: Subscription;
  userData: object;

  email: string;
  password: string;
  isEmailValid: any = null;
  isPasswordValid: any = null;
  mailValid: boolean = null;
  passwordValid: boolean = null;

  constructor(private _formService: FormValidateService,
    private _router: Router,
    private _httpServ: HttpService,
    public snackBar: MatSnackBar,
    private _userService: UserService) { }

  ngOnInit() {
    this.subscription = this._userService.message.subscribe(userStatus => {
      if (userStatus["avilableUserData"]) {
        this.userData = this._userService.getUserData();
      }
    });
    this.userData = this._userService.getUserData();
    if (this.userData['isActive']) {
      this._router.navigate(['/pending-tasks']);
    }
  }


  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

  login() {
    this.isEmailValid = this._formService.emailValidate(this.email);
    this.isPasswordValid = this._formService.passwordValidate(this.password);
    // // if mail is valid
    // if (this.isEmailValid.isValid) {
    //   this.mailValid = true;
    // }
    // else {
    //   this.mailValid = false;
    //   // if password is valid
    // } if (this.isPasswordValid.isValid.isValid) {
    //   this.passwordValid = true;
    // }
    // else {
    //   this.passwordValid = false;
    // }

    // if both email and passwords are valid
    if (this.isEmailValid.isValid && this.isPasswordValid.isValid) {
      let params = {
        action: 'adminLogin',
        emailId: this.email,
        passwordHash: this.password
      }
      this._httpServ.post(params, this.subUrl).subscribe(resp => {
        // console.log(resp);
        if (resp['status'] == 'success') {
          this._userService.setUserData(resp["userData"]);
          this._router.navigate(['/pending-tasks']);
          this.openSnackBar('Login Successful', "5000", "close");
        }
        else {
          this.openSnackBar('Invalid Credentials', "5000", "close");
          this.email = null;
          this.password = null;
          // this.mailValid = null;
          // this.passwordValid = null;
        }
      });
    }
  }
}
