import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AppUrls } from 'src/environments/app-urls';
import { HttpService } from 'src/app/services/http.service';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-add-puppy-preventative',
  templateUrl: './add-puppy-preventative.component.html',
  styleUrls: ['./add-puppy-preventative.component.css']
})
export class AddPuppyPreventativeComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;

  data = [
    'SpeciesType',
    'WorkArea',
    'TreatMent'
  ];

  // data holders
  SpeciesType: any;
  WorkArea: any;

  // input options
  @ViewChild('txtNameOfGuideline') txtNameOfGuideline: ElementRef;

  // select options  
  @ViewChild('SpeciesOption') SpeciesOption: ElementRef;
  @ViewChild('WAOption') WAOption: ElementRef;


  constructor(private _http: HttpService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
  }

  addTreatMentGuideline() {
    let params = {
      action: 'addPuppyTreatmentGuidelines',
      speciesTypeID: this.SpeciesOption.nativeElement.value == undefined ? '' : this.SpeciesOption.nativeElement.value,
      workAreaID: this.WAOption.nativeElement.value == undefined ? '' : this.WAOption.nativeElement.value,
      isPuppyPreventive: 1,
      nameOfGuideline: this.txtNameOfGuideline.nativeElement.value == undefined ? '' : this.txtNameOfGuideline.nativeElement.value,
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = "success") {
        this.openSnackBar(resp['msg'], '5000', 'close');
        this.SpeciesOption.nativeElement.value = 'Select Species';
        this.WAOption.nativeElement.value = 'Select WorkArea';
        this.txtNameOfGuideline.nativeElement.value = null;
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  getCategories(type) {
    let params = {
      action: 'getCategories',
      categoryType: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        if (type == 'SpeciesType') {
          this.SpeciesType = resp;
        }
        else if (type == 'WorkArea') {
          this.WorkArea = resp;
        }
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}
