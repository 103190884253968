import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { AdminSelectedAnimalComponent } from './components/admin-panel/admin-selected-animal/admin-selected-animal.component';
import { AdminTasksComponent } from './components/admin-panel/admin-tasks/admin-tasks.component';
import { AddAnimalComponent } from './components/admin-panel/add-animal/add-animal.component';
import { AdminMatingsComponent } from './components/admin-panel/admin-matings/admin-matings.component';
import { AddMatingComponent } from './components/admin-panel/add-mating/add-mating.component';
import { AnimalListComponent } from './components/admin-panel/animal-list/animal-list.component';
import { MatingsListComponent } from './components/admin-panel/matings-list/matings-list.component';
import { FixedValuesComponent } from './components/admin-panel/fixed-values/fixed-values.component';
import { PuppyPrevListComponent } from './components/admin-panel/puppy-prev-list/puppy-prev-list.component';
import { AdultPrevListComponent } from './components/admin-panel/adult-prev-list/adult-prev-list.component';
import { PuppyPrevComponent } from './components/admin-panel/puppy-prev/puppy-prev.component';
import { AdultPrevComponent } from './components/admin-panel/adult-prev/adult-prev.component';
import { AddPuppyPreventativeComponent } from './components/admin-panel/add-puppy-preventative/add-puppy-preventative.component';
import { AddAdultPreventativeComponent } from './components/admin-panel/add-adult-preventative/add-adult-preventative.component';
import { VeternarySubjectsComponent } from './components/admin-panel/veternary-subjects/veternary-subjects.component';
import { TreatmentsListComponent } from './components/admin-panel/treatments-list/treatments-list.component';
import { InactiveAnimalListComponent } from './components/admin-panel/inactive-animal-list/inactive-animal-list.component';
import { PuppyTreatmentListComponent } from './components/admin-panel/puppy-treatment-list/puppy-treatment-list.component';
import { PregnancyTreatmentListComponent } from './components/admin-panel/pregnancy-treatment-list/pregnancy-treatment-list.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'selected-animal/:id',
    component: AdminSelectedAnimalComponent, runGuardsAndResolvers: 'always'
  },
  {
    path: 'selected-mating/:id',
    component: AdminMatingsComponent, runGuardsAndResolvers: 'always'
  },
  {
    path: 'pending-tasks',
    component: AdminTasksComponent
  },
  {
    path: 'add-animal',
    component: AddAnimalComponent
  },
  // {
  //   path: 'matings',
  //   component: AdminMatingsComponent
  // } ,
  {
    path: 'matings-list',
    component: MatingsListComponent
  },
  {
    path: 'add-mating',
    component: AddMatingComponent
  },
  {
    path: 'animal-list',
    component: AnimalListComponent
  },
  {
    path: 'inactive-animal-list',
    component: InactiveAnimalListComponent
  },
  {
    path: 'fixed-values',
    component: FixedValuesComponent
  },
  {
    path: 'puppy-preventative-list',
    component: PuppyPrevListComponent
  },
  {
    path: 'puppy-preventative/:id',
    component: PuppyPrevComponent
  },
  {
    path: 'add-puppy-preventative',
    component: AddPuppyPreventativeComponent
  },

  {
    path: 'adult-preventative-list',
    component: AdultPrevListComponent
  },
  {
    path: 'adult-preventative/:id',
    component: AdultPrevComponent
  },
  {
    path: 'add-adult-preventative',
    component: AddAdultPreventativeComponent
  },
  {
    path: 'veternary-subjects',
    component: VeternarySubjectsComponent
  },
  {
    path: 'treatments-list',
    component: TreatmentsListComponent
  }, 
  {
    path: 'puppy-treatment-list',
    component: PuppyTreatmentListComponent
  },
  {
    path: 'pregnancy-treatment-list',
    component: PregnancyTreatmentListComponent
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: '**',
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
