import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  ElementRef,
  ViewChild
} from "@angular/core";
import { HttpService } from "src/app/services/http.service";
import { UserService } from "src/app/services/user.service";
import { MatSnackBar } from "@angular/material";
import { AppUrls } from "src/environments/app-urls";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-bs4";
import { Router, NavigationEnd } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-fixed-values",
  templateUrl: "./fixed-values.component.html",
  styleUrls: ["./fixed-values.component.css"]
})
export class FixedValuesComponent implements OnInit {
  dataTable: any;
  subUrl: string = AppUrls._animalInfoModule;
  isEditable: any = {};
  mdFinal: string;

  data = [
    "Treatment",
    "Category",
    "BreedType",
    "BehaviourType",
    "ColorType",
    "FurType",
    "DefectType",
    "ServiceType",
    "ClientDetails",
    "PregnancyMethod",
    "PuppyStatusMaster",
    "ClassType",
    "SpeciesType",
    "Veterinary",
    "Reasons",
    "Prescription",
    "Gender",
    "Owner",
    "WorkArea",
    "ExitReasons",
    "Destination",
    "PreventivePattern",
    "DefinitiveDiagnoses",
    "DifferentialDiagnoses",
    "PrimaryProblems"
  ];

  // Input options
  TreatName: string;
  locName: string;
  cateName: string;
  BreedName: string;
  BehaviourName: string;
  ColorName: string;
  FurName: string;
  DefectName: string;
  ServiceName: string;
  ClientName: any;
  PregnancyName: string;
  PuppyName: string;
  ClassName: string;
  SpeciesName: string;
  VeterName: string;
  ReasonName: string;
  PrescName: string;
  GenderName: string;
  OwnerName: string;
  WorkName: string;
  ExitName: string;
  DestName: string;
  PreventiveName: string;
  DefinitiveDiagnosesName: string;
  DifferentialDiagnosesName: string;
  PrimaryProblemsName: string;

  // Data Holders
  Treatment: any;
  Location: any;
  Category: any;
  BreedType: any;
  BehaviourType: any;
  ColorType: any;
  FurType: any;
  DefectType: any;
  ServiceType: any;
  ClientDetails: any;
  PregnancyMethod: any;
  PuppyStatusMaster: any;
  ClassType: any;
  SpeciesType: any;
  Veterinary: any;
  Reasons: any;
  Prescription: any;
  Gender: any;
  Owner: any;
  WorkArea: any;
  ExitReasons: any;
  Destination: any;
  PreventivePattern: any;
  DefinitiveDiagnoses: any;
  DifferentialDiagnoses: any;
  PrimaryProblems: any;

  @ViewChild("treatMD") treatMD: ElementRef;
  @ViewChild("locationMD") locationMD: ElementRef;
  @ViewChild("categoryMD") categoryMD: ElementRef;
  @ViewChild("BreedTypeMD") BreedTypeMD: ElementRef;
  @ViewChild("behaviourTypeMD") behaviourTypeMD: ElementRef;
  @ViewChild("ColorTypeMD") ColorTypeMD: ElementRef;
  @ViewChild("furTypeMD") furTypeMD: ElementRef;
  @ViewChild("defectTypeMD") defectTypeMD: ElementRef;
  @ViewChild("serviceTypeMD") serviceTypeMD: ElementRef;
  @ViewChild("clientDetailsMD") clientDetailsMD: ElementRef;
  @ViewChild("pregnancyMethodMD") pregnancyMethodMD: ElementRef;
  @ViewChild("puppyStatusMasterMD") puppyStatusMasterMD: ElementRef;
  @ViewChild("classTypesMD") classTypesMD: ElementRef;
  @ViewChild("speciesTypeMD") speciesTypeMD: ElementRef;
  @ViewChild("veterinaryMD") veterinaryMD: ElementRef;
  @ViewChild("reasonsMD") reasonsMD: ElementRef;
  @ViewChild("prescriptionMD") prescriptionMD: ElementRef;
  @ViewChild("genderMD") genderMD: ElementRef;
  @ViewChild("ownerMD") ownerMD: ElementRef;
  @ViewChild("workAreaMD") workAreaMD: ElementRef;
  @ViewChild("exitReasonsMD") exitReasonsMD: ElementRef;
  @ViewChild("destinationMD") destinationMD: ElementRef;
  @ViewChild("preventivePatternMD") preventivePatternMD: ElementRef;
  @ViewChild("DefinitiveDiagnosesMD") DefinitiveDiagnosesMD: ElementRef;
  @ViewChild("DifferentialDiagnosesMD") DifferentialDiagnosesMD: ElementRef;
  @ViewChild("PrimaryProblemsMD") PrimaryProblemsMD: ElementRef;

  constructor(
    private _http: HttpService,
    private _router: Router,
    private _userService: UserService,
    public snackBar: MatSnackBar,
    private chRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
    this._router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    this._router.events.subscribe(evt => {
      if (evt instanceof NavigationEnd) {
        this._router.navigated = false;
        // window.scrollTo(0, 0);
      }
    });
    this.getLocations();
  }

  getCategoriesNew(type) {
    let params = {
      action: "getCategories",
      categoryType: type
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp.length) {
        // console.log(resp);

        if (type == "Treatment") {
          this.Treatment = resp;
          $("#treatmentsTable")
            .DataTable()
            .clear();
          $("#treatmentsTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#treatmentsTable").DataTable();
          }, 1);
        } else if (type == "Location") {
          this.Location = resp;
          $("#locationsTable")
            .DataTable()
            .clear();
          $("#locationsTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#locationsTable").DataTable();
          }, 1);
        } else if (type == "Category") {
          this.Category = resp;
          $("#categorysTable")
            .DataTable()
            .clear();
          $("#categorysTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#categorysTable").DataTable();
          }, 1);
        } else if (type == "BreedType") {
          this.BreedType = resp;
          $("#BreedTypesTable")
            .DataTable()
            .clear();
          $("#BreedTypesTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#BreedTypesTable").DataTable();
          }, 1);
        } else if (type == "BehaviourType") {
          this.BehaviourType = resp;
          $("#BehaviourTypeTable")
            .DataTable()
            .clear();
          $("#BehaviourTypeTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#BehaviourTypeTable").DataTable();
          }, 1);
        } else if (type == "ColorType") {
          this.ColorType = resp;
          $("#ColorTypeTable")
            .DataTable()
            .clear();
          $("#ColorTypeTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#ColorTypeTable").DataTable();
          }, 1);
        } else if (type == "FurType") {
          this.FurType = resp;
          $("#FurTypeTable")
            .DataTable()
            .clear();
          $("#FurTypeTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#FurTypeTable").DataTable();
          }, 1);
        } else if (type == "DefectType") {
          this.DefectType = resp;
          $("#DefectTypeTable")
            .DataTable()
            .clear();
          $("#DefectTypeTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#DefectTypeTable").DataTable();
          }, 1);
        } else if (type == "ServiceType") {
          this.ServiceType = resp;
          $("#ServiceTypeTable")
            .DataTable()
            .clear();
          $("#ServiceTypeTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#ServiceTypeTable").DataTable();
          }, 1);
        } else if (type == "ClientDetails") {
          this.ClientDetails = resp;
          $("#ClientDetailsTable")
            .DataTable()
            .clear();
          $("#ClientDetailsTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#ClientDetailsTable").DataTable();
          }, 1);
        } else if (type == "PregnancyMethod") {
          this.PregnancyMethod = resp;
          $("#PregnancyMethodTable")
            .DataTable()
            .clear();
          $("#PregnancyMethodTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#PregnancyMethodTable").DataTable();
          }, 1);
        } else if (type == "PuppyStatusMaster") {
          this.PuppyStatusMaster = resp;
          $("#PuppyStatusMasterTable")
            .DataTable()
            .clear();
          $("#PuppyStatusMasterTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#PuppyStatusMasterTable").DataTable();
          }, 1);
        } else if (type == "ClassType") {
          this.ClassType = resp;
          $("#ClassTypesTable")
            .DataTable()
            .clear();
          $("#ClassTypesTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#ClassTypesTable").DataTable();
          }, 1);
        } else if (type == "SpeciesType") {
          this.SpeciesType = resp;
          $("#SpeciesTypeTable")
            .DataTable()
            .clear();
          $("#SpeciesTypeTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#SpeciesTypeTable").DataTable();
          }, 1);
        } else if (type == "Veterinary") {
          this.Veterinary = resp;
          $("#VeterinaryTable")
            .DataTable()
            .clear();
          $("#VeterinaryTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#VeterinaryTable").DataTable();
          }, 1);
        } else if (type == "Reasons") {
          this.Reasons = resp;
          $("#reasonsTable")
            .DataTable()
            .clear();
          $("#reasonsTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#reasonsTable").DataTable();
          }, 1);
        } else if (type == "Prescription") {
          this.Prescription = resp;
          $("#prescriptionTable")
            .DataTable()
            .clear();
          $("#prescriptionTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#prescriptionTable").DataTable();
          }, 1);
        } else if (type == "Gender") {
          this.Gender = resp;
          $("#genderTable")
            .DataTable()
            .clear();
          $("#genderTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#genderTable").DataTable();
          }, 1);
        } else if (type == "Owner") {
          this.Owner = resp;
          $("#ownerTable")
            .DataTable()
            .clear();
          $("#ownerTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#ownerTable").DataTable();
          }, 1);
        } else if (type == "WorkArea") {
          this.WorkArea = resp;
          $("#WorkAreaTable")
            .DataTable()
            .clear();
          $("#WorkAreaTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#WorkAreaTable").DataTable();
          }, 1);
        } else if (type == "ExitReasons") {
          this.ExitReasons = resp;
          $("#exitReasonsTable")
            .DataTable()
            .clear();
          $("#exitReasonsTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#exitReasonsTable").DataTable();
          }, 1);
        } else if (type == "Destination") {
          this.Destination = resp;
          $("#destinationTable")
            .DataTable()
            .clear();
          $("#destinationTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#destinationTable").DataTable();
          }, 1);
        } else if (type == "PreventivePattern") {
          this.PreventivePattern = resp;
          $("#preventivePatternTable")
            .DataTable()
            .clear();
          $("#preventivePatternTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#preventivePatternTable").DataTable();
          }, 1);
        } else if (type == "DefinitiveDiagnoses") {
          this.DefinitiveDiagnoses = resp;
          $("#DefinitiveDiagnosesTable")
            .DataTable()
            .clear();
          $("#DefinitiveDiagnosesTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#DefinitiveDiagnosesTable").DataTable();
          }, 1);
        } else if (type == "DifferentialDiagnoses") {
          this.DifferentialDiagnoses = resp;
          $("#DifferentialDiagnosesTable")
            .DataTable()
            .clear();
          $("#DifferentialDiagnosesTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#DifferentialDiagnosesTable").DataTable();
          }, 1);
        } else if (type == "PrimaryProblems") {
          this.PrimaryProblems = resp;
          $("#PrimaryProblemsTable")
            .DataTable()
            .clear();
          $("#PrimaryProblemsTable")
            .DataTable()
            .destroy();
          setTimeout(() => {
            $("#PrimaryProblemsTable").DataTable();
          }, 1);
        }
      }
    });
  }

  getCategories(type) {
    let params = {
      action: "getCategories",
      categoryType: type
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp.length) {
        if (type == "Treatment") {
          this.Treatment = resp;
          setTimeout(() => {
            $("#treatmentsTable").DataTable();
          }, 100);
        } else if (type == "Location") {
          this.Location = resp;
          setTimeout(() => {
            $("#locationsTable").DataTable();
          }, 100);
        } else if (type == "Category") {
          this.Category = resp;
          setTimeout(() => {
            $("#categorysTable").DataTable();
          }, 100);
        } else if (type == "BreedType") {
          this.BreedType = resp;
          setTimeout(() => {
            $("#BreedTypesTable").DataTable();
          }, 100);
        } else if (type == "BehaviourType") {
          this.BehaviourType = resp;
          setTimeout(() => {
            $("#BehaviourTypeTable").DataTable();
          }, 100);
        } else if (type == "ColorType") {
          this.ColorType = resp;
          setTimeout(() => {
            $("#ColorTypeTable").DataTable();
          }, 100);
        } else if (type == "FurType") {
          this.FurType = resp;
          setTimeout(() => {
            $("#FurTypeTable").DataTable();
          }, 100);
        } else if (type == "DefectType") {
          this.DefectType = resp;
          setTimeout(() => {
            $("#DefectTypeTable").DataTable();
          }, 100);
        } else if (type == "ServiceType") {
          this.ServiceType = resp;
          setTimeout(() => {
            $("#ServiceTypeTable").DataTable();
          }, 100);
        } else if (type == "ClientDetails") {
          this.ClientDetails = resp;
          setTimeout(() => {
            $("#ClientDetailsTable").DataTable();
          }, 100);
        } else if (type == "PregnancyMethod") {
          this.PregnancyMethod = resp;
          setTimeout(() => {
            $("#PregnancyMethodTable").DataTable();
          }, 100);
        } else if (type == "PuppyStatusMaster") {
          this.PuppyStatusMaster = resp;
          setTimeout(() => {
            $("#PuppyStatusMasterTable").DataTable();
          }, 100);
        } else if (type == "ClassType") {
          this.ClassType = resp;
          setTimeout(() => {
            $("#ClassTypesTable").DataTable();
          }, 100);
        } else if (type == "SpeciesType") {
          this.SpeciesType = resp;
          setTimeout(() => {
            $("#SpeciesTypeTable").DataTable();
          }, 100);
        } else if (type == "Veterinary") {
          this.Veterinary = resp;
          setTimeout(() => {
            $("#VeterinaryTable").DataTable();
          }, 100);
        } else if (type == "Reasons") {
          this.Reasons = resp;
          setTimeout(() => {
            $("#reasonsTable").DataTable();
          }, 100);
        } else if (type == "Prescription") {
          this.Prescription = resp;
          setTimeout(() => {
            $("#prescriptionTable").DataTable();
          }, 100);
        } else if (type == "Gender") {
          this.Gender = resp;
          setTimeout(() => {
            $("#genderTable").DataTable();
          }, 100);
        } else if (type == "Owner") {
          this.Owner = resp;
          setTimeout(() => {
            $("#ownerTable").DataTable();
          }, 100);
        } else if (type == "WorkArea") {
          this.WorkArea = resp;
          setTimeout(() => {
            $("#WorkAreaTable").DataTable();
          }, 100);
        } else if (type == "ExitReasons") {
          this.ExitReasons = resp;
          setTimeout(() => {
            $("#exitReasonsTable").DataTable();
          }, 100);
        } else if (type == "Destination") {
          this.Destination = resp;
          setTimeout(() => {
            $("#destinationTable").DataTable();
          }, 100);
        } else if (type == "PreventivePattern") {
          this.PreventivePattern = resp;
          setTimeout(() => {
            $("#preventivePatternTable").DataTable();
          }, 100);
        } else if (type == "DefinitiveDiagnoses") {
          this.DefinitiveDiagnoses = resp;
          setTimeout(() => {
            $("#DefinitiveDiagnosesTable").DataTable();
          }, 100);
        } else if (type == "DifferentialDiagnoses") {
          this.DifferentialDiagnoses = resp;
          setTimeout(() => {
            $("#DifferentialDiagnosesTable").DataTable();
          }, 100);
        } else if (type == "PrimaryProblems") {
          this.PrimaryProblems = resp;
          setTimeout(() => {
            $("#PrimaryProblemsTable").DataTable();
          }, 100);
        }
      }
    });
  }

  addCategory(type, value) {
    let params = {
      action: "addMasterDetails",
      categoryType: type,
      masterDetailName: value
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.getLocations();
        this.openSnackBar(resp["msg"], "5000", "close");
        this.TreatName = null;
        this.cateName = null;
        this.BreedName = null;
        this.BehaviourName = null;
        this.ColorName = null;
        this.FurName = null;
        this.DefectName = null;
        this.ServiceName = null;
        this.ClientName = null;
        this.PregnancyName = null;
        this.PuppyName = null;
        this.ClassName = null;
        this.SpeciesName = null;
        this.VeterName = null;
        this.ReasonName = null;
        this.PrescName = null;
        this.GenderName = null;
        this.OwnerName = null;
        this.WorkName = null;
        this.ExitName = null;
        this.DestName = null;
        this.PreventiveName = null;
      } else {
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  addLocation(value) {
    //   console.log(value);

    let params = {
      action: "addLocation",
      locationName: value
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.getLocations();
        this.openSnackBar(resp["msg"], "5000", "close");
        this.locName = null;
      } else {
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  deleteCategory(id, type) {
    let params = {
      action: "deleteMasterDetails",
      masterDetailID: id
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.getCategoriesNew(type);
        this.openSnackBar(resp["msg"], "5000", "close");
      } else {
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  deleteLocation(id) {
    let params = {
      action: "deleteLocation",
      locationid: id
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.getLocations();
        this.openSnackBar(resp["msg"], "5000", "close");
      } else {
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  getLocations() {
    let params = {
      action: "getLocations"
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      console.log(resp);
      if (resp.length) {
        this.Location = resp;
        $("#locationsTable")
          .DataTable()
          .clear();
        $("#locationsTable")
          .DataTable()
          .destroy();
        setTimeout(() => {
          $("#locationsTable").DataTable();
        }, 1);
      }
    });
  }

  updateCategory(cat, type) {
    if (type == "Treatment") {
      this.mdFinal = this.treatMD.nativeElement.value;
    }
    // else if (type == 'Location') {
    //     this.mdFinal = this.locationMD.nativeElement.value
    // }
    else if (type == "Category") {
      this.mdFinal = this.categoryMD.nativeElement.value;
    } else if (type == "BreedType") {
      this.mdFinal = this.BreedTypeMD.nativeElement.value;
    } else if (type == "BehaviourType") {
      this.mdFinal = this.BreedTypeMD.nativeElement.value;
    } else if (type == "ColorType") {
      this.mdFinal = this.ColorTypeMD.nativeElement.value;
    } else if (type == "FurType") {
      this.mdFinal = this.furTypeMD.nativeElement.value;
    } else if (type == "DefectType") {
      this.mdFinal = this.defectTypeMD.nativeElement.value;
    } else if (type == "ServiceType") {
      this.mdFinal = this.serviceTypeMD.nativeElement.value;
    } else if (type == "ClientDetails") {
      this.mdFinal = this.clientDetailsMD.nativeElement.value;
    } else if (type == "PregnancyMethod") {
      this.mdFinal = this.pregnancyMethodMD.nativeElement.value;
    } else if (type == "PuppyStatusMaster") {
      this.mdFinal = this.puppyStatusMasterMD.nativeElement.value;
    } else if (type == "ClassType") {
      this.mdFinal = this.classTypesMD.nativeElement.value;
    } else if (type == "SpeciesType") {
      this.mdFinal = this.speciesTypeMD.nativeElement.value;
    } else if (type == "Veterinary") {
      this.mdFinal = this.veterinaryMD.nativeElement.value;
    } else if (type == "Reasons") {
      this.mdFinal = this.reasonsMD.nativeElement.value;
    } else if (type == "Prescription") {
      this.mdFinal = this.prescriptionMD.nativeElement.value;
    } else if (type == "Gender") {
      this.mdFinal = this.genderMD.nativeElement.value;
    } else if (type == "Owner") {
      this.mdFinal = this.ownerMD.nativeElement.value;
    } else if (type == "WorkArea") {
      this.mdFinal = this.workAreaMD.nativeElement.value;
    } else if (type == "ExitReasons") {
      this.mdFinal = this.exitReasonsMD.nativeElement.value;
    } else if (type == "Destination") {
      this.mdFinal = this.destinationMD.nativeElement.value;
    } else if (type == "PreventivePattern") {
      this.mdFinal = this.preventivePatternMD.nativeElement.value;
    } else if (type == "DefinitiveDiagnoses") {
      this.mdFinal = this.DefinitiveDiagnosesMD.nativeElement.value;
    } else if (type == "DifferentialDiagnoses") {
      this.mdFinal = this.DifferentialDiagnosesMD.nativeElement.value;
    } else if (type == "PrimaryProblems") {
      this.mdFinal = this.PrimaryProblemsMD.nativeElement.value;
    }
    let params = {
      action: "updateMasterDetails",
      masterDetailID: cat.masterDetailID,
      masterDetailName: this.mdFinal
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        cat.masterDetailName = this.mdFinal;
        // this.getCategoriesNew(type);
        this.openSnackBar(resp["msg"], "5000", "close");
      } else {
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  updateLocation(loc) {
    this.mdFinal = this.locationMD.nativeElement.value;
    let params = {
      action: "updateLocation",
      locationid: loc.locationID,
      locationName: this.mdFinal
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        loc.locationName = this.mdFinal;
        // this.getlocations();
        this.openSnackBar(resp["msg"], "5000", "close");
      } else {
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }
}
