import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { MatSnackBar } from '@angular/material';
import { AppUrls } from 'src/environments/app-urls';
import { Router } from '@angular/router';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
declare var $: any;

@Component({
  selector: 'app-admin-tasks',
  templateUrl: './admin-tasks.component.html',
  styleUrls: ['./admin-tasks.component.css']
})
export class AdminTasksComponent implements OnInit {
  subUrl: string = AppUrls._animalInfoModule;
  dataTable: any;
  pendingTasks: any = [];

  constructor(private _httpServ: HttpService,
    public snackBar: MatSnackBar,
    private _router: Router, private chRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.getPendingTasks();
    // this.chRef.markForCheck();
    setTimeout(() => {
      $('#tasksTable').DataTable();
    }, 500);
  }

  getPendingTasks() {
    let params = {
      action: 'viewAnimalPendingTasks'
    }
    this._httpServ.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.pendingTasks = resp;
        setTimeout(() => {
          $('#tasksTable').DataTable();
        }, 500);
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}
