import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AppUrls } from 'src/environments/app-urls';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-admin-sidemenu',
  templateUrl: './admin-sidemenu.component.html',
  styleUrls: ['./admin-sidemenu.component.css']
})
export class AdminSidemenuComponent implements OnInit {
  subUrl: string = AppUrls._animalInfoModule;
  constructor(private _http: HttpService, private _router: Router,public snackBar: MatSnackBar) { }

  @Input() activeItem : any;

  ngOnInit() {

  }

  getLastAnimalID() {
    let params = {
      action: 'getMaxAnimalId'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {  
      if (resp.length) {
        this._router.navigate([`/selected-animal/${resp[0].maxId}`]);
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  getLastMatingID() {
    let params = {
      action: 'getMaxMatingId'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {  
      if (resp.length) {
        this._router.navigate([`/selected-mating/${resp[0].maxId}`]);
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }
}
