import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule }   from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HttpService } from './services/http.service';
import { FormValidateService } from './services/form-validate.service';
import { HeaderComponent} from './components/header/header.component';
import { MaterialModule } from './material.module';
import { AdminSelectedAnimalComponent } from './components/admin-panel/admin-selected-animal/admin-selected-animal.component';
import { AdminTasksComponent } from './components/admin-panel/admin-tasks/admin-tasks.component';
import { AdminSidemenuComponent } from './components/admin-panel/admin-sidemenu/admin-sidemenu.component';
import { AddAnimalComponent } from './components/admin-panel/add-animal/add-animal.component';
import { AdminMatingsComponent } from './components/admin-panel/admin-matings/admin-matings.component';
import { AddMatingComponent } from './components/admin-panel/add-mating/add-mating.component';
import { AnimalListComponent } from './components/admin-panel/animal-list/animal-list.component';
import { InactiveAnimalListComponent } from './components/admin-panel/inactive-animal-list/inactive-animal-list.component';
import { MatingsListComponent } from './components/admin-panel/matings-list/matings-list.component';
import { UserService } from './services/user.service';
import { FixedValuesComponent } from './components/admin-panel/fixed-values/fixed-values.component';
import { PuppyPrevListComponent } from './components/admin-panel/puppy-prev-list/puppy-prev-list.component';
import { AdultPrevListComponent } from './components/admin-panel/adult-prev-list/adult-prev-list.component';
import { PuppyPrevComponent } from './components/admin-panel/puppy-prev/puppy-prev.component';
import { AdultPrevComponent } from './components/admin-panel/adult-prev/adult-prev.component';
import { AddPuppyPreventativeComponent } from './components/admin-panel/add-puppy-preventative/add-puppy-preventative.component';
import { AddAdultPreventativeComponent } from './components/admin-panel/add-adult-preventative/add-adult-preventative.component';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { VeternarySubjectsComponent } from './components/admin-panel/veternary-subjects/veternary-subjects.component';
import { TreatmentsListComponent } from './components/admin-panel/treatments-list/treatments-list.component';
import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { AdminContainerComponent } from './components/admin-panel/admin-container/admin-container.component';
import { PuppyTreatmentListComponent } from './components/admin-panel/puppy-treatment-list/puppy-treatment-list.component';
import { DatePipe } from '@angular/common';
import { MatDatepickerModule, MatFormFieldModule, MatInputModule, MatNativeDateModule } from '@angular/material';
import { PregnancyTreatmentListComponent } from './components/admin-panel/pregnancy-treatment-list/pregnancy-treatment-list.component';
// import { RouterModule } from '@angular/router';
// import { MatNativeDateModule } from '@angular/material';
// import * as $ from "jquery";
// import { MatMomentDateModule } from "@angular/material-moment-adapter";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    AdminSelectedAnimalComponent,
    AdminTasksComponent,
    AdminSidemenuComponent,
    AddAnimalComponent,
    AdminMatingsComponent,
    AddMatingComponent,
    AnimalListComponent,
    InactiveAnimalListComponent,
    MatingsListComponent,
    FixedValuesComponent,
    PuppyPrevListComponent,
    AdultPrevListComponent,
    PuppyPrevComponent,
    AdultPrevComponent,
    AddPuppyPreventativeComponent,
    AddAdultPreventativeComponent,
    VeternarySubjectsComponent,
    TreatmentsListComponent,
    ConfirmModalComponent,
    AdminContainerComponent,
    PuppyTreatmentListComponent,
    PregnancyTreatmentListComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    Ng2GoogleChartsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    // RouterModule
    // MatMomentDateModule
  ],
  providers: [HttpService,FormValidateService,UserService,DatePipe,MatDatepickerModule],
  entryComponents: [ConfirmModalComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
