import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  subscription: Subscription;
  userData: any;
  
  constructor(private _userService: UserService, private _router: Router,
    public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.subscription = this._userService.message.subscribe(userStatus => {
      if (userStatus["avilableUserData"]) {
        this.userData = this._userService.getUserData();
      }
    });
    this.userData = this._userService.getUserData();
    // console.log(this.userData);
  }
  
  logout() {
    this._userService.setUserData(null);
    this._router.navigate(['/login']);
    localStorage.removeItem('selectedTab');
    this.openSnackBar('Logout Successful', '5000', 'close');
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }
}
