import { Component, OnInit, ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material';
import { AppUrls } from 'src/environments/app-urls';
import { MatTable, MatPaginator } from '@angular/material';

import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-admin-matings',
  templateUrl: './admin-matings.component.html',
  styleUrls: ['./admin-matings.component.css']
})
export class AdminMatingsComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;
  subscription: Subscription;
  userData: object;
  isEditable: any = [];
  displayedColumns: string[] = ['Confirmation', 'Dates', 'Options'];
  dataSource: any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatTable) table: MatTable<any>;
  // @ViewChildren('mateChecked') mateChecked: QueryList<ElementRef>;
  @ViewChildren('mateDate') mateDate: QueryList<ElementRef>;
  @ViewChild('datePickAdd') datePickAdd: ElementRef;
  @ViewChild('chkConfirmAdd') chkConfirmAdd: ElementRef;
  @ViewChild('pregnantConfit') pregnantConfit: ElementRef;
  @ViewChild('matingEntryDate') matingEntryDate: ElementRef;
  @ViewChild('matingExitDate') matingExitDate: ElementRef;
  @ViewChild('estimatedDate') estimatedDate: ElementRef;
  @ViewChild('birthRealDate') birthRealDate: ElementRef;
  
  @ViewChild('chipIDMale') chipIDMale: ElementRef;
  @ViewChild('categoryOptionMale') categoryOptionMale: ElementRef;
  @ViewChild('tattooOptionMale') tattooOptionMale: ElementRef;
  @ViewChild('speciesOption') speciesOption: ElementRef;
  @ViewChild('animalIdMale') animalIdMale: ElementRef;
  selectedMatingId: number;
  selectedMatingDetails: any;

  data = [
    'SpeciesType',
    'Category',
    'PregnancyMethod'
  ];

  data2 = [
    'MaleCount', 'FemaleCount', 'BDCount', 'LDCount', 'WCount', 'totalCount'
  ]

  // Select Options 
  @ViewChild('pregnancyOption') pregnancyOption: ElementRef;

  // data holders
  SpeciesType: any;
  Category: any;
  matingConfirmed: any;
  PregnancyMethod: any;
  animalBirthReport: any;
  navigationData: any;
  initNavigationData: any;

  // Input options
  foreSeen: any;
  problemsInfo: any;
  // estimatedDateValue: any;
  matingExitDateValue: any;

  // Dates options
  @ViewChildren('birthDate') birthDate: QueryList<ElementRef>;
  @ViewChild('birthDateAdd') birthDateAdd: ElementRef;

  // counts
  maleCount: number;
  femaleCount: number;
  BDCount: number;
  LDCount: number;
  WCount: number;
  totalCount: number;

  // Select Option Properties
  @ViewChildren('genderOption') genderOption: QueryList<ElementRef>;
  @ViewChild('genderOptionAdd') genderOptionAdd: ElementRef;

  // checkBox options
  @ViewChild('isPregnant') isPregnant: ElementRef;
  @ViewChild('notMated') notMated: ElementRef;
  @ViewChild('problems') problems: ElementRef;
  @ViewChild('rule') rule: ElementRef;
  @ViewChild('fixedPattern') fixedPattern: ElementRef;
  @ViewChild('notAlive') notAlive: ElementRef;

  // radio Button options
  @ViewChildren('LD') LD: QueryList<ElementRef>;
  @ViewChildren('BD') BD: QueryList<ElementRef>;
  @ViewChildren('W') W: QueryList<ElementRef>;
  @ViewChild('LD1') LD1: ElementRef;
  @ViewChild('BD1') BD1: ElementRef;
  @ViewChild('W1') W1: ElementRef;
  maleTattoo: any;
  maleChip: any;
  maleCategory: any;
  femaleCategory: any;

  constructor(private _http: HttpService, private _activeRoute: ActivatedRoute,
    private _userService: UserService, public snackBar: MatSnackBar, private _router: Router) { }


  ngOnInit() {
    this.subscription = this._userService.message.subscribe(userStatus => {
      if (userStatus["avilableUserData"]) {
        this.userData = this._userService.getUserData();
      }
    });
    this.userData = this._userService.getUserData();
    // get activated Route
    this._activeRoute.params.subscribe(resp => {
      this.selectedMatingId = resp['id'];
    });
    this.getAllInitialData();
    
  }

  getAllInitialData() {
    this.getMatingConfdDatesByMatingId();
    this.getAnimalBirthReport();
    this.isMaxID(this.selectedMatingId);
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
    for (let i = 0; i < this.data2.length; i++) {
      this.getCounts(this.data2[i]);
    }
    this.getSelectedMatingDetails();
    setTimeout(() => {
      this.datePickers();
      this.getCategoryBySpecies();
    }, 3000);
    
  }

  // navigaion methods
  isMaxID(id) {
    let params = {
      action: 'isMaxMatingId',
      animalId: id
    }
    this._http.post(params, this.subUrl).subscribe(resp => {

      if (resp) {
        this.initNavigationData = resp['nav'];
      }
    });
  }

  getNavigationDetails(position) {
    let params = {
      action: 'getMatingNavigations',
      matingID: this.selectedMatingId,
      navPos: position
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.navigationData = resp[0];
        this.selectedMatingId = this.navigationData.id;
        this._router.navigate([`/selected-mating/${this.selectedMatingId}`]);
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

  // Intially Loaded Methods

  getCategories(type) {
    let params = {
      action: 'getCategories',
      categoryType: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        if (type == 'SpeciesType') {
          this.SpeciesType = resp;
        }
        else if (type == 'Category') {
          this.Category = resp;
        }
        else if (type == 'PregnancyMethod') {
          this.PregnancyMethod = resp;
        }
      }
    });
  }

  getCategoryBySpecies() {
    console.log('sp type: ', this.selectedMatingDetails.speciesTypeID);
    
    for (let i = 0; i < 2; i++) {
      let params = {
        action: 'getCategoryBySpecies',
        'speciesID': this.selectedMatingDetails.speciesTypeID,
        sex: i == 0 ? 'M' : 'F'
      }
      this._http.post(params, this.subUrl).subscribe(resp => {
        if (resp) {
          if (i == 0) {
            this.maleCategory = resp;
          }
          else {
            this.femaleCategory = resp;
          }
        }
        // else {
        //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
        // }
      });
    }

  }

  getSelectedMatingDetails() {
    let params = {
      action: 'viewMatingDetailsById',
      matingId: this.selectedMatingId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.selectedMatingDetails = resp[0];
        this.foreSeen = this.selectedMatingDetails.foreseenWeanedPuppies;
        this.problemsInfo = this.selectedMatingDetails.problemDetails;
        // this.problems.nativeElement.checked = this.selectedMatingDetails.isProblems == 1 ? true : false;
      }
    });
  }

  getChipByTattooMale() {
    let params = {
      action: 'getChipByTattoo',
      tattoo: this.tattooOptionMale.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp.length) {
        this.maleChip = resp[0];
      }
      else {
        this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      }
    });
  }

   getTattooByCategoryMale() {
    let params = {
      action: 'getTattooByCateg',
      catId: this.categoryOptionMale.nativeElement.value,
      sex: 'M'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.maleTattoo = resp;
        console.log(this.maleTattoo);
        
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  getCounts(type) {
    let params = {
      action: 'getPuppyCounts',
      matingID: this.selectedMatingId,
      type: type
    }

    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        if (type == 'MaleCount') {
          this.maleCount = resp.cnt;
        }
        else if (type == 'FemaleCount') {
          this.femaleCount = resp.cnt;
        }
        else if (type == 'BDCount') {
          this.BDCount = resp.cnt;
        }
        else if (type == 'LDCount') {
          this.LDCount = resp.cnt;
        }
        else if (type == 'WCount') {
          this.WCount = resp.cnt;
        }
        else if (type == 'totalCount') {
          this.totalCount = resp.cnt;
        }
      }
    });
  }

  getAnimalBirthReport() {
    let params = {
      action: 'getPuppyByMatingId',
      matingId: this.selectedMatingId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.animalBirthReport = resp;
      }
    });
  }

  getMatingConfdDatesByMatingId() {
    let params = {
      action: 'getMatingConfdDatesByMatingId',
      matingId: this.selectedMatingId
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.matingConfirmed = resp;
      }
    });
  }

  datePickers() {
    if (this.matingConfirmed) {
      for (let i = 0; i < this.matingConfirmed.length; i++) {
        $("#picker").delegate("#datePick" + i, "focusin", function () {
          $(this).datepicker({
            dateFormat: 'yy-mm-dd',
            onSelect: function (datetext) {
              var d = new Date();
              datetext = datetext;
              // datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
              $(this).val(datetext);
            }
          });
        });
      }
    }
    if (this.animalBirthReport) {
      for (let i = 0; i < this.animalBirthReport.length; i++) {
        $("#picker2").delegate("#birthDate" + i, "focusin", function () {
          $(this).datepicker({
            dateFormat: 'yy-mm-dd',
            onSelect: function (datetext) {
              var d = new Date();
              datetext = datetext;
              // datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
              $(this).val(datetext);
            }
          });
        });
      }
    }

    $('#datePickAdd,#birthDateAdd,#birthRealDate,#matingExitDate,#estimatedDate,#matingEntryDate,#estimatedDate').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        // datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        datetext = datetext;
        $(this).val(datetext);
      }
    });

    $('#pregnantConfit').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        // datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        datetext = datetext;
        $(this).val(datetext);
        console.log(datetext)
        $("#chkPregConfirmed").removeAttr("disabled");
      }
    });
  }

  // Later loaded  Methods

  viewSelectedAnimal(report) {
    this._router.navigate([`/selected-animal/${report.animalID}`]);
  }

  addToAnimal(report, i) {
    let params = {
      action: 'addAnimalData',
      dateOfBirth: this.birthDate.toArray()[i].nativeElement.value == undefined ? '' : this.birthDate.toArray()[i].nativeElement.value,
      puppyDetailsID: report.puppyDetailsID,
      sex: this.genderOption.toArray()[i].nativeElement.value == 'Select Gender' ? '' : this.genderOption.toArray()[i].nativeElement.value,
      matingID: this.selectedMatingId,
      origin: this.selectedMatingId,
      speciesTypeID: this.selectedMatingDetails.speciesTypeID,
      categoryID: this.selectedMatingDetails.feMaleCategoryID,
      fatherAnimalID: this.selectedMatingDetails.maleAnimalID,
      motherAnimalID: this.selectedMatingDetails.feMaleAnimalID,
      fatherTattoo: this.selectedMatingDetails.maleTattoo,
      motherTattoo: this.selectedMatingDetails.feMaleTattoo,
      fatherChipID: this.selectedMatingDetails.maleChipID,
      motherChipID: this.selectedMatingDetails.feMaleChipID,
      resultOfWeaning: this.BD.toArray()[i].nativeElement.checked == true ? 'BD' : this.LD.toArray()[i].nativeElement.checked == true ? 'LD' :
        this.W.toArray()[i].nativeElement.checked == true ? 'W' : ''
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.openSnackBar(resp['msg'], '5000', 'close');
        this.getAnimalBirthReport();
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  addAnimalBirthReport() {
    if (this.birthDateAdd.nativeElement.value != '' && this.genderOptionAdd.nativeElement.value != 'Select Gender' &&
      (this.BD1.nativeElement.checked == true || this.LD1.nativeElement.checked == true || this.W1.nativeElement.checked == true)) {
      let params = {
        action: 'addPuppyData',
        matingID: this.selectedMatingId,
        birthDate: this.birthDateAdd.nativeElement.value,
        sex: this.genderOptionAdd.nativeElement.value == 'Select Gender' ? '' : this.genderOptionAdd.nativeElement.value,
        puppyStatusMasterID: this.BD1.nativeElement.checked == true ? 'BD' : this.LD1.nativeElement.checked == true ? 'LD' :
          this.W1.nativeElement.checked == true ? 'W' : ''
      }
      this._http.post(params, this.subUrl).subscribe(resp => {
        if (resp['status'] = 'success') {
          this.getAnimalBirthReport();
          for (let i = 0; i < this.data2.length; i++) {
            this.getCounts(this.data2[i]);
          }
          setTimeout(() => {
            this.datePickers();
          }, 500);
          this.openSnackBar(resp['msg'], '5000', 'close');
          this.birthDateAdd.nativeElement.value = null;
          this.BD1.nativeElement.checked = false;
          this.LD1.nativeElement.checked = false;
          this.W1.nativeElement.checked = false;
          this.genderOptionAdd.nativeElement.value = 'Select Gender';
        }
        else {
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
      });
    }
  }

  updateAnimalBirthReport(report, i) {
    let params = {
      action: 'updatePuppyDetails',
      puppyDetailsID: report.puppyDetailsID,
      birthDate: this.birthDate.toArray()[i].nativeElement.value == undefined ? '' : this.birthDate.toArray()[i].nativeElement.value,
      sex: this.genderOption.toArray()[i].nativeElement.value == 'Select Gender' ? '' : this.genderOption.toArray()[i].nativeElement.value,
      puppyStatusMasterID: this.BD.toArray()[i].nativeElement.checked == true ? 'BD' : this.LD.toArray()[i].nativeElement.checked == true ? 'LD' :
        this.W.toArray()[i].nativeElement.checked == true ? 'W' : ''
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getAnimalBirthReport();
        for (let i = 0; i < this.data2.length; i++) {
          this.getCounts(this.data2[i]);
        }
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  deleteAnimalBirthReport(report) {
    let params = {
      action: 'deletePuppyDetails',
      puppyDetailsID: report.puppyDetailsID
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getAnimalBirthReport();
        for (let i = 0; i < this.data2.length; i++) {
          this.getCounts(this.data2[i]);
        }
        setTimeout(() => {
          this.datePickers();
        }, 500);
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  addMatingDate() {
    let params = {
      action: 'addMatingConfirmedDetails',
      matingID: this.selectedMatingId,
      // matingConformation: this.chkConfirmAdd.nativeElement.checked ? 1 : 0,
      matingConformation: 1,
      matingConfirmedDate: this.datePickAdd.nativeElement.value
    }
    console.log(this.matingEntryDate.nativeElement.value,  this.matingExitDate.nativeElement.value)
    if(this.datePickAdd.nativeElement.value != ''){
      if (this.datePickAdd.nativeElement.value >= this.matingEntryDate.nativeElement.value && 
        this.datePickAdd.nativeElement.value <= this.matingExitDate.nativeElement.value){
          this._http.post(params, this.subUrl).subscribe(resp => {
            if (resp['status'] == 'success') {
              this.getMatingConfdDatesByMatingId();
              setTimeout(() => {
                this.datePickers();
              }, 500);
              this.openSnackBar(resp['msg'], '5000', 'close');
              // this.chkConfirmAdd.nativeElement.checked = false;
              this.datePickAdd.nativeElement.value = null;
            }
            else {
              this.openSnackBar(resp['msg'], '5000', 'close');
            }
          });
      }else {
        this.openSnackBar("Sorry, Confirmation Date shoud be between Entry and Exit dates", '5000', 'close');
      }
    }
    else {
      this.openSnackBar("Sorry, Please select Confirmation Date", '5000', 'close');
    }
  }

  updateMatingDate(id, i) {
    // let mateCheckedOne: boolean = this.mateChecked.toArray()[i].nativeElement.checked;
    let mateDateOne: string = this.mateDate.toArray()[i].nativeElement.value;
    let params = {
      action: 'updateMatingConfdDates',
      matingConfirmedDatesID: id,
      // matingConformation: mateCheckedOne == true ? 1 : 0,
      matingConformation: 1,
      matingConfirmedDate: mateDateOne
    }
    console.log(this.matingEntryDate.nativeElement.value,  this.matingExitDate.nativeElement.value) 
    if(mateDateOne != ''){
      if (this.datePickAdd.nativeElement.value >= this.matingEntryDate.nativeElement.value && 
        this.datePickAdd.nativeElement.value <= this.matingExitDate.nativeElement.value){
          this._http.post(params, this.subUrl).subscribe(resp => {
            if (resp['status'] == 'success') {
              this.getMatingConfdDatesByMatingId();
              this.openSnackBar(resp['msg'], '5000', 'close');
            }
            else {
              this.openSnackBar(resp['msg'], '5000', 'close');
            }
          });
        }else {
        this.openSnackBar("Sorry, Confirmation Date shoud be between Entry and Exit dates", '5000', 'close');
      }
    }else {
      this.openSnackBar("Sorry, Confirmation Date should not be empty", '5000', 'close');
    }    
  }

  deleteMatingDate(id) {
    let params = {
      action: 'deleteMatingConfdDates',
      matingConfirmedDatesID: id
    }

    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] == 'success') {
        this.getMatingConfdDatesByMatingId();
        setTimeout(() => {
          this.datePickers();
        }, 500);
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  updateMatingAllDetails() {
    let params = {
      action: 'updateMatingDetails',
      speciesTypeID: this.selectedMatingDetails.speciesTypeID,
      maleCategoryID: this.categoryOptionMale.nativeElement.value == 'Select Category' ? 0 : this.categoryOptionMale.nativeElement.value,
      feMaleCategoryID: this.selectedMatingDetails.feMaleCategoryID,
      maleTattoo: this.tattooOptionMale.nativeElement.value == 'Select Tattoo' ? '' : this.tattooOptionMale.nativeElement.value,
      feMaleTattoo: this.selectedMatingDetails.feMaleTattoo,
      maleChipID: this.chipIDMale.nativeElement.value == undefined ? '' : this.chipIDMale.nativeElement.value,
      feMaleChipID: this.selectedMatingDetails.feMaleChipID,
      maleAnimalID: this.animalIdMale.nativeElement.value == undefined ? '' : this.animalIdMale.nativeElement.value,
      feMaleAnimalID: this.selectedMatingDetails.feMaleAnimalID,
      heatOrMatingEntry: this.matingEntryDate.nativeElement.value,
      heatOrMatingExit: this.matingExitDate.nativeElement.value,
      heatDetectedAndNotMated: this.notMated.nativeElement.checked ? 1 : 0,
      isPregnancyConfirmed: this.isPregnant.nativeElement.checked ? 1 : 0,
      pregnancyConfirmedDate: this.pregnantConfit.nativeElement.value,
      pregnancyMethodID: this.pregnancyOption.nativeElement.value == 'Select Method' ? '' : this.pregnancyOption.nativeElement.value,
      foreseenWeanedPuppies: this.foreSeen == undefined ? '' : this.foreSeen,
      birthForecastDate: this.estimatedDate.nativeElement.value,
      realBirthDate: this.birthRealDate.nativeElement.value,
      // isProblems: this.problems.nativeElement.checked ? 1 : 0,
      isProblems: 1,
      problemDetails: this.problemsInfo == undefined ? '' : this.problemsInfo,
      isIfNotAliveNewborns: this.notAlive.nativeElement.checked ? 1 : 0,
      matingId: this.selectedMatingId,
      isPreventiveRule: this.rule.nativeElement.checked ? 1 : 0,
    }
    console.log(params);
    if(this.isPregnant.nativeElement.checked == 1){
      if(this.pregnantConfit.nativeElement.value == '0000-00-00' || this.pregnantConfit.nativeElement.value == '' || this.pregnantConfit.nativeElement.value == null){
        this.openSnackBar('Please add Pregnancy Confirmation Date', '5000', 'close');
      }else{
        this._http.post(params, this.subUrl).subscribe(resp => {
          if (resp['status'] = 'success') {
            this.getSelectedMatingDetails();
            this.openSnackBar(resp['msg'], '5000', 'close');
          }
          else {
            this.openSnackBar(resp['msg'], '5000', 'close');
          }
        });
      }
    } else {
      this._http.post(params, this.subUrl).subscribe(resp => {
        if (resp['status'] = 'success') {
          this.getSelectedMatingDetails();
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
        else {
          this.openSnackBar(resp['msg'], '5000', 'close');
        }
      });
    }
    
  }

  onValueChange(value: Date): void {
    console.log(123);
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }
}
