import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppUrls } from 'src/environments/app-urls';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';
import { MatSnackBar } from '@angular/material';
import * as $ from 'jquery';
declare var $: any;

@Component({
  selector: 'app-add-animal',
  templateUrl: './add-animal.component.html',
  styleUrls: ['./add-animal.component.css']
})
export class AddAnimalComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;
  subscription: Subscription;
  userData: object;

  data = [
    'Category',
    'ClassType',
    'SpeciesType',
    'BreedType',
    'Gender',
    'BehaviourType',
    'ColorType',
    'FurType',
    'DefectType',
    'ExitReasons',
    'Destination',
    'Owner'
  ];


  // properties to hold data
  Category: any;
  Location: any;
  ClassType: any;
  SpeciesType: any;
  BreedType: any;
  Gender: any;
  BehaviourType: any;
  ColorType: any;
  FurType: any;
  DefectType: any;
  ExitReasons: any;
  Destination: any;
  Owner: any;

  // Select Option Properties
  categoryOption: any = 'Select Category';
  classOption: any = 'Select Class';
  speciesOption: any = 'Select Species';
  breedOption: any = 'Select Breed';
  genderOption: any = 'Select Gender';
  behaveOption: any = 'Select Behaviour';
  colorOption: any = 'Select Color';
  furOption: any = 'Select Fur';
  defectOption: any = 'Select Defect';
  exitOption: any = 'Select Reason';
  destinationOption: any = 'Select Destination';
  ownerOption: any = 'Select Owner';
  assignToUser: any = 'Select Assign To';
  locationOption: any = 'Select Location';
  puppyOption: any = 'Select Pattern';
  adultOption: any = 'Select Pattern';

  // Input Properties
  weight: number;
  tattoo: number;
  chip: number;
  label: any;
  offered: any;
  reserved: any;
  others: any;
  birthDate: any;
  litter: any;
  notes: any
  assignToDate: any;
  assignToTask: string;
  addLocationDate: any;
  // dates options
  @ViewChild('plannedDate') plannedDate: ElementRef;
  @ViewChild('realDate') realDate: ElementRef;
  addWeightDate: any;

  // Check Box properties
  curve: any;
  nuetered: any;
  procedure: any;
  active: any;
  // Radio Buttons
  wean: any

  constructor(private _http: HttpService,
    private _userService: UserService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.subscription = this._userService.message.subscribe(userStatus => {
      if (userStatus["avilableUserData"]) {
        this.userData = this._userService.getUserData();
      }
    });
    this.userData = this._userService.getUserData();
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
    this.getLocations();
    setTimeout(() => {
      this.datePickers();
    }, 1000);
  }

  getLocations() {
    let params = {
      action: 'getLocations'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {  
      if (resp) {
        this.Location = resp;
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  addAnimal() {  
    let params = {
      action: 'addAnimalList',
      categoryId: this.categoryOption == 'Select Category' ? '' : this.categoryOption.masterDetailID,
      locationId: this.locationOption == 'Select Location' ? '' : this.locationOption.locationID,
      currentLocation: this.locationOption == 'Select Location' ? '' : this.locationOption.locationName,
      weight: this.weight == undefined ? '' : this.weight,
      classTypeId: this.classOption == 'Select Class' ? '' : this.classOption.masterDetailID,
      speciesTypeId: this.speciesOption == 'Select Species' ? '' : this.speciesOption.masterDetailID,
      breedTypeId: this.breedOption == 'Select Breed' ? '' : this.behaveOption.masterDetailID,
      sex: this.genderOption == 'Select Gender' ? '' : this.genderOption,
      tattoo: this.tattoo == undefined ? '' : this.tattoo,
      chipId: this.chip == undefined ? '' : this.chip,
      origin: '',
      behaviourTypeId: this.behaveOption == 'Select Behaviour' ? '' : this.behaveOption.masterDetailID,
      colorTypeId: this.colorOption == 'Select Color' ? '' : this.colorOption.masterDetailID,
      furTypeId: this.furOption == 'Select Fur' ? '' : this.furOption.masterDetailID,
      defectTypeId: this.defectOption == 'Select Defect' ? '' : this.defectOption.masterDetailID,
      label: this.label == undefined ? '' : this.label,
      offeredTo: this.offered == undefined ? '' : this.offered,
      reservedFor: this.reserved == undefined ? '' : this.reserved,
      others: this.others == undefined ? '' : this.others,
      plannedDate: this.plannedDate.nativeElement.value == undefined ? '' : this.plannedDate.nativeElement.value,
      realDate: this.realDate.nativeElement.value == undefined ? '' : this.realDate.nativeElement.value,
      exitReasonId: this.exitOption == 'Select Reason' ? '' : this.exitOption.masterDetailID,
      destination: this.destinationOption == 'Select Destination' ? '' : this.destinationOption.masterDetailID,
      owner: this.ownerOption == 'Select Owner' ? '' : this.ownerOption.masterDetailID,
      notes: this.notes == undefined ? '' : this.notes,
      usedForTheGrowthCurve: this.curve == true ? 1 : 0,
      neuteredAbunak: this.nuetered == true ? 1 : 0,
      inUseOrToBeUsedInFuture: this.procedure == true ? 1 : 0,
      createdBy: this.userData['Email']
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
  
      if (resp['status'] = 'success') {
        this.openSnackBar(resp['msg'], '5000', 'close');
        this.resetAll();
      }
      else{
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  getCategories(type) {
    let params = {
      action: 'getCategories',
      categoryType: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {   
        if (type == 'Category') {
          this.Category = resp;
        }
        else if (type == 'ClassType') {
          this.ClassType = resp;
        }
        else if (type == 'SpeciesType') {
          this.SpeciesType = resp;

        }
        else if (type == 'BreedType') {
          this.BreedType = resp;

        }
        else if (type == 'Gender') {
          this.Gender = resp;

        }
        else if (type == 'BehaviourType') {
          this.BehaviourType = resp;
        }
        else if (type == 'ColorType') {
          this.ColorType = resp;
        }
        else if (type == 'FurType') {
          this.FurType = resp;

        }
        else if (type == 'DefectType') {
          this.DefectType = resp;
        }
        else if (type == 'ExitReasons') {
          this.ExitReasons = resp;
          // console.log(this.ExitReasons);
        }
        else if (type == 'Destination') {
          this.Destination = resp;
        }
        else if (type == 'Owner') {
          this.Owner = resp;
        }
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

  resetAll() {
    // Reset Select Options
    this.categoryOption = 'Select Category';
    this.locationOption = 'Select Location';
    this.classOption = 'Select Class';
    this.speciesOption = 'Select Species';
    this.breedOption = 'Select Breed';
    this.genderOption = 'Select Gender';

    this.behaveOption = 'Select Behaviour';
    this.colorOption = 'Select Color';
    this.furOption = 'Select Fur';
    this.defectOption = 'Select Defect';
    this.exitOption = 'Select Reason';
    this.destinationOption = 'Select Destination';
    this.ownerOption = 'Select Owner';

    // Reset Inputs
    this.weight = null;
    this.tattoo = null;
    this.chip = null;
    this.label = null;
    this.offered = null;
    this.reserved = null;
    this.others = null;
    this.plannedDate.nativeElement.value = null;
    this.realDate.nativeElement.value = null;
    this.notes = null;
    // Reset Check Box properties
    this.curve = null;
    this.nuetered = null;
    this.procedure = null;
  }

  datePickers() {  
    $('#txtPlannedDate,#txtRealDate').datepicker({
      dateFormat: 'yy-mm-dd',
      onSelect: function (datetext) {
        var d = new Date();
        datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
        $(this).val(datetext);
      }
    });
  }
}
