import { Injectable } from '@angular/core';
import { AppUrls } from 'src/environments/app-urls';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
// import {Http, ResponseContentType} from '@angular/common/http';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  baseUrl: string = AppUrls._baseUrl;

  constructor(private http: HttpClient) { }

  ngOnInit() { }

  post(paramsObj, subUrl): Observable<any> {
    // let myheader: HttpHeaders = new HttpHeaders();
    // if (token != null) {
    //   myheader = myheader.set('Content-Type', 'application/x-www-form-urlencoded').set('Authorization', 'Bearer ' + token);
    // }
    // else {
    // myheader = myheader.set('Content-Type', 'application/x-www-form-urlencoded');
    // }
    let body: HttpParams = null;
    if (paramsObj) {
      for (var key in paramsObj) {
        body = body === null ? new HttpParams() : body;
        body = body.set(`${key}`, `${paramsObj[key]}`);
        // console.log(body);
      } return this.http.post(this.baseUrl + subUrl, body);
      // return this.http.post(this.baseUrl + subUrl, body, { headers: myheader });
      // return this.http.get('./assets/data.json');
    }
  }

  downloadFile(params,url): Observable<any> {
    let body = new HttpParams();
    body = body.append('action',params.action);
    body = body.append('file',params.file);
    return this.http.get(this.baseUrl + url,{params:body});
  }
}
