import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormValidateService {

  constructor() { }
  emailValidate(email) {
    let reg = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (email == "") {
      return {
        isValid: false,
        errorMsg: "Email Must be filled"
      };
    } else if (email == null) {
      return {
        isValid: false,
        errorMsg: "Email Must be filled"
      };
    } else if (!email.match(reg)) {
      return {
        isValid: false,
        errorMsg: "Enter Valid Email Address"
      };
    } else {
      return {
        isValid: true,
        errorMsg: null
      };
    }
  }

  passwordValidate(password) {
    if (password == "") {
      return {
        isValid: false,
        errorMsg: "Password Must be filled"
      };
    } else if (password == null) {
      return {
        isValid: false,
        errorMsg: "Password Must be filled"
      };
    } else {
      return {
        isValid: true,
        errorMsg: null
      };
    }
  }
}
