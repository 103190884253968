import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { AppUrls } from 'src/environments/app-urls';
import { HttpService } from 'src/app/services/http.service';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-puppy-prev-list',
  templateUrl: './puppy-prev-list.component.html',
  styleUrls: ['./puppy-prev-list.component.css']
})
export class PuppyPrevListComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;
  puppiesPreventData: any;
  dataTable: any;

  constructor(private _http: HttpService, private chRef: ChangeDetectorRef, private _router: Router, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getPuppiesPreventList();
  }
  
  getPuppiesPreventList() {
    let params = {
      action: 'getPuppyPrevnList'
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.puppiesPreventData = resp;
        this.chRef.detectChanges();
        const table: any = $('#puppyListTable');
        this.dataTable = table.DataTable();
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  viewPuppyPreventative(puppy) {
    this._router.navigate([`/puppy-preventative/${puppy.id}`]);
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}
