import { Injectable } from "@angular/core";
import * as crypto from "crypto-js";
import { Router } from "@angular/router";
import { AppUrls } from "../../environments/app-urls";
import { Subject, Observable, of, BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private _encrypted: any;
  private _decript: any;
  private _userData: any;
  public message = new Subject<object>();
  private _hashKey: string = AppUrls._hashKey;
  constructor(private _router: Router) { }

  setUserData(userObj): void {
    this._encrypted = crypto.AES.encrypt(
      JSON.stringify(userObj),
      this._hashKey
    ).toString();
    localStorage.setItem("userData", this._encrypted);
    this.message.next({ avilableUserData: true });
  }

  getUserData() {
    let localStoreData = localStorage.getItem("userData");
    if (localStoreData !== null && localStoreData) {
      this._decript = crypto.AES.decrypt(localStoreData, this._hashKey);
      try {
        this._userData = JSON.parse(this._decript.toString(crypto.enc.Utf8));
        this._userData["isActive"] = true;
        return this._userData;
      } catch (e) {
        localStorage.clear();
        this._router.navigate(["/"]);
        return false;
      }
    } else {
      return false;
    }
  }
}
