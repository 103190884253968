import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatSnackBarModule,
  // MatTableModule,
  // MatPaginatorModule,
  // MatFormFieldModule,
  // MatInputModule,
  // MatSortModule,
  // MatCheckboxModule,
  // MatButtonModule,
  MatDatepickerModule,
  // MatNativeDateModule
  MatSelectModule,
  MatDialogModule,
  MatIconModule,
  MatFormFieldModule,
  MatNativeDateModule,


} from "@angular/material";

@NgModule({
  declarations: [],
  imports: [
    MatSelectModule,
    MatDialogModule,
    // CommonModule,
    MatSnackBarModule,
    MatIconModule ,
    // MatTableModule,
    // MatPaginatorModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatSortModule,
    // MatCheckboxModule,
    // MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    // MatNativeDateModule
  ],
  exports: [
    MatSelectModule,
    MatSnackBarModule,
    MatDialogModule,
    // MatTableModule,
    // MatPaginatorModule,
    // MatFormFieldModule,
    // MatInputModule,
    // MatSortModule,
    // MatCheckboxModule,
    // MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatIconModule ,
    // MatNativeDateModule
  ]
})
export class MaterialModule { }
