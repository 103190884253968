import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AppUrls } from 'src/environments/app-urls';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material';


@Component({
  selector: 'app-adult-prev',
  templateUrl: './adult-prev.component.html',
  styleUrls: ['./adult-prev.component.css']
})
export class AdultPrevComponent implements OnInit {

  data = [
    'SpeciesType',
    'WorkArea',
    'TreatMent'
  ];
  // data holders
  selectedAdultID: number;
  selectedAdultData: any;
  SpeciesType: any;
  WorkArea: any;
  TreatMent: any
  treatmentNames: any;

  // input options
  @ViewChild('txtNameOfGuideline') txtNameOfGuideline: ElementRef;
  treatmentNotes: any;
  treatmentDays: any;
  treatmentBirth: any;

  // select options  
  @ViewChild('SpeciesOption') SpeciesOption: ElementRef;
  @ViewChild('WAOption') WAOption: ElementRef;
  @ViewChild('treatMentName') treatMentName: ElementRef;

  subUrl: string = AppUrls._animalInfoModule;
  constructor(private _http: HttpService, private _activeRoute: ActivatedRoute, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this._activeRoute.params.subscribe(resp => {
      this.selectedAdultID = resp['id'];
    });
    this.getSelectedAdultData();
    this.getTreatmentsTable();
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
  }

  getSelectedAdultData() {
    let params = {
      action: 'getAdultTreatmentGuideline',
      id: this.selectedAdultID
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp.length) {
        this.selectedAdultData = resp[0];
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  getCategories(type) {
    let params = {
      action: 'getCategories',
      categoryType: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        if (type == 'SpeciesType') {
          this.SpeciesType = resp;
        }
        else if (type == 'WorkArea') {
          this.WorkArea = resp;
        }
        else if (type == 'TreatMent') {
          this.TreatMent = resp;
        }
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  deleteTreatment(treat) {
    let params = {
      action: 'deleteTreatmentGuidelinesChild',
      id: treat.id
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getTreatmentsTable();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  addTreatment() {
    let params = {
      action: 'addTreatmentGuidelinesChild',
      treatmentGuidelineID: this.selectedAdultData.id,
      days: this.treatmentDays == undefined ? '' : this.treatmentDays,
      startAfterBirthOrDeley: this.treatmentBirth == undefined ? '' : this.treatmentBirth,
      notes: this.treatmentNotes == undefined ? '' : this.treatmentNotes,
      treatmentID: this.treatMentName.nativeElement.value == undefined ? '' : this.treatMentName.nativeElement.value,

    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getTreatmentsTable();
        this.treatMentName.nativeElement.value = 'Select TreatMent';
        this.treatmentBirth = null;
        this.treatmentNotes = null;
        this.treatmentDays = null;
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }
  
  updateTreatMent() {
    let params = {
      action: 'updatePuppyTreatmentGdln',
      id: this.selectedAdultData.id,
      nameOfGuideline: this.txtNameOfGuideline.nativeElement.value == undefined ? '' : this.txtNameOfGuideline.nativeElement.value,
      speciesTypeID: this.SpeciesOption.nativeElement.value == undefined ? '' : this.SpeciesOption.nativeElement.value,
      workAreaID: this.WAOption.nativeElement.value == undefined ? '' : this.WAOption.nativeElement.value
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp['status'] = 'success') {
        this.getSelectedAdultData();
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
      else {
        this.openSnackBar(resp['msg'], '5000', 'close');
      }
    });
  }

  getTreatmentsTable() {
    let params = {
      action: 'getTreatmentName',
      tretmntGuidlnID: this.selectedAdultID
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        this.treatmentNames = resp;
      }
      // else {
      //   this.openSnackBar('OOPS ! Something Went Wrong !', '5000', 'close');
      // }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

}
