import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AppUrls } from 'src/environments/app-urls';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
declare var $: any;


@Component({
  selector: 'app-treatments-list',
  templateUrl: './treatments-list.component.html',
  styleUrls: ['./treatments-list.component.css']
})
export class TreatmentsListComponent implements OnInit {

  subUrl: string = AppUrls._animalInfoModule;

  treatMentsList:any;

  constructor(private _httpServ: HttpService   
  ) { }

  ngOnInit() {
    this.getTreatMentsList();
    // this.chRef.markForCheck();
    
  }

  getTreatMentsList(){
    let params = {
      action: 'getDataVisibleTractment'
    }
    this._httpServ.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        // console.log(resp);
        
        this.treatMentsList = resp;
        // setTimeout(() => {
        //   $('#treatmentsListID').DataTable();
        // }, 500);
        setTimeout(() => {
          $('#treatmentsListID').DataTable(
            {
              orderCellsTop: true,
              fixedHeader: true,
              dom: 'Bfrtip',
              buttons: [
                'copyHtml5',
                'excelHtml5',
                'csvHtml5',
                'print'
              ]
            }
          );
        }, 500);
      } 
    });
  }
}
