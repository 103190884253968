import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { AppUrls } from 'src/environments/app-urls';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';
import { MatSnackBar } from '@angular/material';

declare var $: any;

@Component({
  selector: 'app-animal-list',
  templateUrl: './animal-list.component.html',
  styleUrls: ['./animal-list.component.css']
})
export class AnimalListComponent implements OnInit {
  subUrl: string = AppUrls._animalInfoModule;
  displayedColumns: string[] = ['Litter Origin', 'Species', 'Category', 'Id', 'Tattoo', 'Chip', 'Sex', 'Birth', 'Way out', 'Location'];
  dataSource: any;
  dataTable: any;
  animalListData: any;

  constructor(private _http: HttpService, public snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getAnimalList('active');
  }

  /* getAllAnimals(event) {
    this.animalListData = [];
    console.log(event)
    if (event == 0) {
      this.getAnimalList(null);
    }
    if (event == 1) {
      this.getAnimalList('active');
    }
    if (event == -1) {
      this.getAnimalList('inactive');
    }
  } */

  getAnimalList(type) {
    let params = {
      action: 'animalList',
      type: type
    }
    this._http.post(params, this.subUrl).subscribe(resp => {
      console.log(resp);
      if (resp) {
        this.animalListData = resp;
        $("#animalListTable").DataTable().clear();
        $("#animalListTable").DataTable().destroy();
        console.log(this.animalListData)
        setTimeout(() => {
          // if (type == 'first') {
            console.log(123456)
            $('#animalListTable thead tr').clone(true).appendTo('#animalListTable thead');
            $('#animalListTable thead tr:eq(1) th').each(function (i) {
              $(this).html(' <input type="text" class="form-control form-control-sm" style="width:100%"/>' +
                '<i class="fa fa-search search-icn" style="position: absolute;color: #aaa; right: 6px;bottom: 19px;"></i>');
              $('input', this).on('keyup change', function () {
                console.log(dataTable.column(i).search(), ' ', this.value);
                if (dataTable.column(i).search() !== this.value) {
                  dataTable.column(i).search("^" + this.value, true, false, true).draw();
                }
              });
            });
          // }
          // dataTable.destroy();

          let dataTable = $('#animalListTable').DataTable({
            destroy: true,
            orderCellsTop: true,
            fixedHeader: true,
            dom: 'Bfrtip',
            'scrollX': true,
            buttons: [
              'copyHtml5',
              'excelHtml5',
              'csvHtml5',
              'print'
            ],
            'oSerach': { 'bSmart': false },
            "columnDefs": [
              {
                "targets": [0],
                "visible": false,
                "searchable": true
              },
              {
                "targets": [1],
                "visible": false,
                "searchable": true
              }
            ]
          });
          // dataTable.draw();
          /* $.fn.dataTable.ext.search.push(
            function (settings, data, dataIndex) {
              var min = $('#datepicker_from').datepicker("getDate");
              var max = $('#datepicker_to').datepicker("getDate");
              var startDate = new Date(data[7]);
              if (min == null && max == null) { return true; }
              if (min == null && startDate <= max) { return true; }
              if (max == null && startDate >= min) { return true; }
              if (startDate <= max && startDate >= min) { return true; }
              return false;
            }
          );
          $("#datepicker_from,#datepicker_to").datepicker({
            dateFormat: 'mm/dd/yy',
            onSelect: function (datetext) {
              var d = new Date();
              datetext = datetext + " " + d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true });
              $(this).val(datetext);
              dataTable.draw();
            }, changeMonth: true, changeYear: true
          });
          // Event listener to the two range filtering inputs to redraw on input
          $('#datepicker_from, #datepicker_to').change(function () {
            dataTable.draw();
          });    */
        }, 500);
      }
    });
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }
}
