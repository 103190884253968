import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList
} from "@angular/core";
import { AppUrls } from "src/environments/app-urls";
import { HttpService } from "src/app/services/http.service";
import { MatSnackBar, MatDialog } from "@angular/material";
import { Subscription } from "rxjs";
import * as $ from "jquery";
import { UserService } from "src/app/services/user.service";
import { ConfirmModalComponent } from "../../confirm-modal/confirm-modal.component";
import { ActivatedRoute } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-veternary-subjects",
  templateUrl: "./veternary-subjects.component.html",
  styleUrls: ["./veternary-subjects.component.css"]
})
export class VeternarySubjectsComponent implements OnInit {
  // Basic Properties
  subUrl: string = AppUrls._animalInfoModule;
  subscription: Subscription;
  userData: object;
  chipID: any;

  //Fixed Values tables Data Holders
  primaryProblems: any;
  DifferentialDiagnoses: any;
  DefinitiveDiagnoses: any;
  WorkArea: any;

  // Individual tabs data holders
  veternarysubData: any;
  animalData: any;
  physicalExamData: any;
  analyticsData: any;
  necropsyData: any;
  documentsData: any;

  // Bound ngModel for default multi select with compareWith attribute
  differentialDiagnoseDatasSelected: any;
  definativeDiagnoseDatasSelected: any;
  primaryProblemsSelected: any;
  differentialDiagnosesExamSelected: any;
  necropsyTabDiffSelected: any;
  necropsyTabDefiSelected: any;

  // speciesType: any;
  // sex: any;
  // defectType: any;
  // location: any;
  // breedType: any;
  // breedTypeID: any;
  // tattoo: any;

  DefinitiveDiagnosesValue: any = [];
  DifferentialDiagnosesValue = [];
  PrimaryProblemsValue: any = [];
  DifferentialExamTabDiagnosesValue: any = [];
  necropsyTabDiffValue: any = [];
  necropsyTabDefiValue: any = [];

  // dynamic div creation
  index: any = 1;
  data = [
    "DefinitiveDiagnoses",
    "DifferentialDiagnoses",
    "PrimaryProblems",
    "WorkArea"
  ];
  TractamentIndicators: any = [
    {
      index: 0,
      note: "",
      date: "",
      days: "",
      workArea: ""
    }
  ];

  // data tab properties
  // @ViewChild("workDate") workDate: ElementRef;
  @ViewChildren("workDate") workDate: QueryList<ElementRef>;
  @ViewChildren("tractMentForm") tractMentForm: QueryList<ElementRef>;
  @ViewChild("visitdate") visitdate: ElementRef;
  @ViewChild("time") time: ElementRef;
  @ViewChild("reasoninquire") reasoninquire: ElementRef;
  @ViewChild("veterinarian") veterinarian: ElementRef;
  @ViewChild("weight") weight: ElementRef;
  @ViewChild("animalId") animalId: ElementRef;
  @ViewChild("inquireOrFollowUp1") inquireOrFollowUp1: ElementRef;
  @ViewChild("inquireOrFollowUp2") inquireOrFollowUp2: ElementRef;
  @ViewChild("inquireOrFollowUp3") inquireOrFollowUp3: ElementRef;

  // Physical Exam Properties
  @ViewChild("physicalTabDate") physicalTabDate: ElementRef;
  @ViewChild("physicalTabWeight") physicalTabWeight: ElementRef;
  @ViewChild("physicalTabTemperature") physicalTabTemperature: ElementRef;
  @ViewChild("physicalTabVeternarian") physicalTabVeternarian: ElementRef;
  @ViewChild("physicalTabNotes") physicalTabNotes: ElementRef;

  // Necropsy Tab properties
  @ViewChild("necroUnsubscribe") necroUnsubscribe: ElementRef;
  @ViewChild("necroDate") necroDate: ElementRef;
  @ViewChild("necroPreserve") necroPreserve: ElementRef;
  @ViewChild("necroResponsible") necroResponsible: ElementRef;
  @ViewChild("necroNotes") necroNotes: ElementRef;

  // Analytics tab upload file pop up properties
  addedFile: any;
  endodedFile: any;
  popUPDescription: string;
  @ViewChild("closeDocumentModal") closeDocumentModal: ElementRef;
  @ViewChild("popUpDate") popUpDate: ElementRef;
  @ViewChild("fileInput") fileInput: ElementRef;

  // Documents tab upload file pop up properties
  @ViewChild("closeDocumentModal1") closeDocumentModal1: ElementRef;
  @ViewChild("fileName") fileName: ElementRef;
  @ViewChild("fileKind") fileKind: ElementRef;
  @ViewChild("fileDate") fileDate: ElementRef;
  @ViewChild("fileSize") fileSize: ElementRef;
  @ViewChild("fileDescription") fileDescription: ElementRef;
  @ViewChild("fileInput1") fileInput1: ElementRef;

  // @ViewChild("myForm") myForm: ElementRef;

  constructor(
    private _http: HttpService,
    private _userService: UserService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _activeRoute: ActivatedRoute
  ) {
    this._activeRoute.queryParams.subscribe(params => {
      this.chipID = params.chipID;
    });
  }

  ngOnInit() {
    this.subscription = this._userService.message.subscribe(userStatus => {
      if (userStatus["avilableUserData"]) {
        this.userData = this._userService.getUserData();
      }
    });
    this.userData = this._userService.getUserData();
    for (let i = 0; i < this.data.length; i++) {
      this.getCategories(this.data[i]);
    }
    setTimeout(() => {
      this.datePickers();
    }, 2000);
    if (this.chipID) {
      this.getVeternaryList();
    }
  }

  // ********* INITIAL METHODS  *************//

  getCategories(type) {
    let params = {
      action: "getCategories",
      categoryType: type
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp.length) {
        if (type == "DifferentialDiagnoses") {
          this.DifferentialDiagnoses = resp;
        } else if (type == "DefinitiveDiagnoses") {
          this.DefinitiveDiagnoses = resp;
        } else if (type == "PrimaryProblems") {
          this.primaryProblems = resp;
        } else if (type == "WorkArea") {
          this.WorkArea = resp;
        }
      }
    });
  }

  // Get All Tabs data by Entered Chid ID
  getVeternaryList() {  
    let params = {
      action: "getAnimalByChipID",
      chipID: this.chipID
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp) {
        console.log(resp);
        //  Data Tab
        this.veternarysubData = resp["DataTab"]["veternary"];
        this.animalData = resp["DataTab"]["animalData"];
        // Initlizing tractment indicators with default values if data is received from backend
        if (resp["DataTab"]["tractment"]) {
          this.TractamentIndicators = [];
          for (let i = 0; i < resp["DataTab"]["tractment"].length; i++) {
            let obj = {
              index: i,
              note: resp["DataTab"]["tractment"][i]["note"],
              date: resp["DataTab"]["tractment"][i]["start_date"],
              days: resp["DataTab"]["tractment"][i]["days"],
              workArea: resp["DataTab"]["tractment"][i]["WorkAreaID"]
            };
            this.TractamentIndicators.push(obj);
          }
        }

        this.differentialDiagnoseDatasSelected = resp["DataTab"]["deferential"];
        this.definativeDiagnoseDatasSelected = resp["DataTab"]["definitive"];
        // Physical Exam Tab
        this.physicalExamData = resp["PhysicalExam"];
        this.primaryProblemsSelected = resp["PhysicalExam"]["primary"];
        this.differentialDiagnosesExamSelected =
          resp["PhysicalExam"]["Pdefinitive"];
        // Necropsy Tab
        this.necropsyData = resp["Necropsy"];
        this.necropsyTabDiffSelected = resp["Necropsy"]["Ndifferential"];
        this.necropsyTabDefiSelected = resp["Necropsy"]["Ndefinitive"];
        // To assign values to multiselect options when data coming from backend for first time
        this.defaultSelectedFromServer();

        // Analytics Tab
        this.analyticsData = resp["Analytics"];
        //  Documents tab
        this.documentsData = resp["Documents"];
      }
    });
  }

  // ********* DATA TAB METHODS  *************//

  dataTabSubmit() {
    // get date input using viewchildren as ngmodel does not detect date picker WHILE SENDING DATA TO BACKEDN
    for (let i = 0; i < this.TractamentIndicators.length; i++) {
      this.TractamentIndicators[i]["date"] = this.workDate.toArray()[
        i
      ].nativeElement.value;
    }
    let params = {
      action: "addVeternarySubjects",
      animalId: this.animalId.nativeElement.value,
      chipID: this.chipID,
      dateOfVisit: this.visitdate.nativeElement.value,
      time: this.time.nativeElement.value,
      reasonForTheInquire: this.reasoninquire.nativeElement.value,
      weight: this.weight.nativeElement.value,
      veterinarian: this.veterinarian.nativeElement.value,
      inquireOrFollowUp1: this.inquireOrFollowUp1.nativeElement.value,
      inquireOrFollowUp2: this.inquireOrFollowUp2.nativeElement.value,
      inquireOrFollowUp3: this.inquireOrFollowUp3.nativeElement.value,
      userName: this.userData["Email"],
      diffrentialDiagnosis: this.DifferentialDiagnosesValue.toString(),
      definitiveDiagnosis: this.DefinitiveDiagnosesValue.toString(),
      tractment: JSON.stringify(this.TractamentIndicators)
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if ((resp["status"] = "success")) {
        this.veternarysubData = resp["DataTab"]["veternary"];
        this.animalData = resp["DataTab"]["animalData"];
        this.differentialDiagnoseDatasSelected = resp["DataTab"]["deferential"];
        this.definativeDiagnoseDatasSelected = resp["DataTab"]["definitive"];
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  addTractamentIndicatorsForm1() {
    let index = 0;
    index++;
    this.TractamentIndicators.push({
      index: index,
      note: "",
      date: "",
      days: "",
      workArea: ""
    });
    setTimeout(() => {
      this.datePickers();
    }, 500);
  }

  openDialog(index) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      width: "500px",
      data: { index: index }
    });

    dialogRef.afterClosed().subscribe(data => {
      console.log(data);
      if (data["index"] != undefined) {
        this.deleteTractamentIndicatorsForm1(data["index"]);
      }
    });
  }

  deleteTractamentIndicatorsForm1(index: Boolean) {
    for (let i = 0; this.TractamentIndicators.length; i++) {
      if (this.TractamentIndicators[i].index === index) {
        this.TractamentIndicators.splice(i, 1);
        break;
      }
    }
    setTimeout(() => {
      this.datePickers();
    }, 500);
  }

  // ********* PHYSICAL EXAM TAB METHODS  *************//

  physicalExamTabSubmit() {
    let params = {
      action: "addPhysicalExam",
      chipID: this.chipID,
      examDate: this.physicalTabDate.nativeElement.value,
      weight: this.physicalTabWeight.nativeElement.value,
      temparature: this.physicalTabTemperature.nativeElement.value,
      veterenarian: this.physicalTabVeternarian.nativeElement.value,
      notes: this.physicalTabNotes.nativeElement.value,
      userName: this.userData["Email"],
      primaryProblems: this.PrimaryProblemsValue.toString(),
      deferentialDiagnosis: this.DifferentialExamTabDiagnosesValue.toString()
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.physicalExamData = resp["PhysicalExam"];
        this.primaryProblemsSelected = resp["PhysicalExam"]["primary"];
        this.differentialDiagnosesExamSelected =
          resp["PhysicalExam"]["Pdefinitive"];
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  // ********* NECROPSY TAB METHODS  *************//

  necropsyTabSubmit() {
    let params = {
      action: "addNecropsy",
      chipID: this.chipID,
      unsubscription: this.necroUnsubscribe.nativeElement.value,
      necropsyDate: this.necroDate.nativeElement.value,
      preservation: this.necroPreserve.nativeElement.value,
      responsible: this.necroResponsible.nativeElement.value,
      notes: this.necroNotes.nativeElement.value,
      userName: this.userData["Email"],
      deferentialDiagnosis: this.necropsyTabDiffValue.toString(),
      definitiveDiagnosys: this.necropsyTabDefiValue.toString()
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.necropsyData = resp["Necropsy"];
        this.necropsyTabDiffSelected = resp["Necropsy"]["Ndifferential"];
        this.necropsyTabDefiSelected = resp["Necropsy"]["Ndefinitive"];
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  // ********* ANALYTICS TAB METHODS  *************//

  sendAnalyticTabToServer() {
    let params = {
      action: "addAnalyticsDocument",
      encoded_string: this.endodedFile,
      mimeType: this.addedFile.type,
      docName: this.addedFile.name,
      ChipID: this.chipID,
      Date: this.popUpDate.nativeElement.value,
      KindTechniquResult: this.popUPDescription,
      userName: this.userData["Email"]
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.analyticsData = resp["Analytics"];
        this.closeDocumentModal.nativeElement.click();
        this.openSnackBar(resp["msg"], "5000", "close");
        this.popUpDate.nativeElement.value = "";
        this.popUPDescription = "";
        this.fileInput.nativeElement.value = "";
      } else {
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  deleteAnalyticTab(analytic) {
    let params = {
      action: "deleteAnalyticsDocument",
      id: analytic.analyticsID,
      chipID: this.chipID,
      docName: analytic.document
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.analyticsData = resp["Analytics"];
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  // ********* DOCUMENTS TAB METHODS  *************//

  sendDocumentTabToServer() {
    let params = {
      action: "addVeternaryDocument",
      encoded_string: this.endodedFile,
      mimeType: this.addedFile.type,
      size: this.fileSize.nativeElement.value,
      name: this.fileName.nativeElement.value,
      chipID: this.chipID,
      kind: this.fileKind.nativeElement.value,
      description: this.fileDescription.nativeElement.value,
      modDate: this.fileDate.nativeElement.value,
      userName: this.userData["Email"]
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.documentsData = resp["Documents"];
        this.closeDocumentModal1.nativeElement.click();
        this.openSnackBar(resp["msg"], "5000", "close");

        // form.reset(); // or form.resetForm();
        this.fileName.nativeElement.value = "";
        this.fileSize.nativeElement.value = "";
        this.fileKind.nativeElement.value = "";
        this.fileDescription.nativeElement.value = "";
        this.fileInput1.nativeElement.value = "";
      }
    });
  }

  deleteDocumentTab(doc) {
    let params = {
      action: "deleteVeternaryDocument",
      id: doc.veternaryDocumentsID,
      chipID: this.chipID,
      docName: doc.document
    };
    this._http.post(params, this.subUrl).subscribe(resp => {
      if (resp["status"] == "success") {
        this.documentsData = resp["Documents"];
        this.openSnackBar(resp["msg"], "5000", "close");
      }
    });
  }

  // ********* OTHER Methods RELATED TO FEW TABS *************//

  defaultSelectedFromServer() {
    // To send already selected values to backend if user does not change anything
    // Data Tab
    if (this.differentialDiagnoseDatasSelected) {
      this.differentialDiagnoseDatasSelected.filter(item => {
        this.DifferentialDiagnosesValue.push(item.masterDetailID);
      });
    }
    if (this.definativeDiagnoseDatasSelected) {
      this.definativeDiagnoseDatasSelected.filter(item => {
        this.DefinitiveDiagnosesValue.push(item.masterDetailID);
      });
    }

    // Physical Exam Tab
    if (this.primaryProblemsSelected) {
      this.primaryProblemsSelected.filter(item => {
        this.PrimaryProblemsValue.push(item.masterDetailID);
      });
    }
    if (this.differentialDiagnosesExamSelected) {
      this.differentialDiagnosesExamSelected.filter(item => {
        this.DifferentialExamTabDiagnosesValue.push(item.masterDetailID);
      });
    }

    // Necropsy Tab
    if (this.necropsyTabDiffSelected) {
      this.necropsyTabDiffSelected.filter(item => {
        this.necropsyTabDiffValue.push(item.masterDetailID);
      });
    }
    if (this.necropsyTabDefiSelected) {
      this.necropsyTabDefiSelected.filter(item => {
        this.necropsyTabDefiValue.push(item.masterDetailID);
      });
    }
  }

  selectedMultipleOptions(event, type) {
    // data tab
    if (type == "Differential") {
      this.DifferentialDiagnosesValue = [];
      event.value.filter(item => {
        this.DifferentialDiagnosesValue.push(item.masterDetailID);
      });
    } else if (type == "Definitive") {
      this.DefinitiveDiagnosesValue = [];
      event.value.filter(item => {
        this.DefinitiveDiagnosesValue.push(item.masterDetailID);
      });
    }
    // Physical Exam Tab
    else if (type == "problems") {
      this.PrimaryProblemsValue = [];
      event.value.filter(item => {
        this.PrimaryProblemsValue.push(item.masterDetailID);
      });
    } else if (type == "DifferentialExamTab") {
      this.DifferentialExamTabDiagnosesValue = [];
      event.value.filter(item => {
        this.DifferentialExamTabDiagnosesValue.push(item.masterDetailID);
      });
    }
    // necropsy tab
    else if (type == "necroDiff") {
      this.necropsyTabDiffValue = [];
      event.value.filter(item => {
        this.necropsyTabDiffValue.push(item.masterDetailID);
      });
    } else if (type == "necroDefi") {
      this.necropsyTabDefiValue = [];
      event.value.filter(item => {
        this.necropsyTabDefiValue.push(item.masterDetailID);
      });
    }
  }

  // ********* GENERAL Methods  *************//

  // Date Pickers
  datePickers() {
    for (let i = 0; i < this.TractamentIndicators.length; i++) {
      // $("#picker").delegate("#workDate" + i, "focusin", function () {
      $("#picker").delegate(".workDate" + i, "focusin", function() {
        $(this).datepicker({
          dateFormat: "yy-mm-dd",
          onSelect: function(datetext) {
            var d = new Date();
            datetext =
              datetext +
              " " +
              d.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                second: "numeric",
                hour12: true
              });
            $(this).val(datetext);
          }
        });
      });
    }

    $(
      "#visitdate, #physicalTabDate,#necroDate,#popUpDate,#fileDate"
    ).datepicker({
      dateFormat: "yy-mm-dd",
      onSelect: function(datetext) {
        var d = new Date();
        datetext =
          datetext +
          " " +
          d.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true
          });
        $(this).val(datetext);
      }
    });
  }

  // Download File
  downloadFile(doc) {
    let params = {
      action: "downloadAnalyticsFile",
      file: doc.document
    };
    this._http.downloadFile(params, this.subUrl).subscribe(resp => {
      var b64toBlob = require("b64-to-blob");
      var contentType = resp.mime_type;
      var b64Data = resp.file_data;
      var blob = b64toBlob(b64Data, contentType);
      const url = window.URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.setAttribute("href", url);
      downloadLink.setAttribute("download", doc.document);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }),
      error => console.log("Error downloading the file"),
      () => console.info("File downloaded successfully");
  }

  // Upload File
  fileUpload(evt) {
    var files = evt.target.files;
    this.addedFile = files[0];
    if (files && this.addedFile) {
      var reader = new FileReader();
      reader.onload = this._handleReaderLoaded.bind(this);
      reader.readAsBinaryString(this.addedFile);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    let encodedImage = btoa(binaryString);
    // this.endodedFile = encodedImage;
    this.endodedFile = encodeURIComponent(encodedImage);
  }

  compareWithFunc(a, b) {
    return a.masterDetailID === b.masterDetailID;
  }

  openSnackBar(msg, duration, action?) {
    this.snackBar.open(msg, action, {
      duration: duration
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
